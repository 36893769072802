import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import STATES from '../constants/states';
import PROVINCES from '../constants/canadian-provinces';
import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup-ontario-professional', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      redirectTo: urlParams.get('redirectTo'),
      schoolQuery: '',
      cookie_name: 'ontario_professional_registration_form',
      loading: false,
      size: '10px',
      color: '#642c8f',
      radius: '50%',
      show_address: true,
      google: require('google-maps-api')(google_api_key, ['places']),
      fields_to_validate: {
        last_name: ['required'],
        first_name: ['required'],
        email: ['required', 'custom'],
        email_confirmation: ['required', 'custom'],
        high_school: ['required'],
        birthdate: ['required', 'custom'],
        grad_type: ['custom'],
        act_score: ['custom'],
        sat_score: ['custom'],
        college_start_semester: ['required'],
        fair: ['required'],
        accept_tos: ['required'],
        professional_type: ['required'],
        professional_type_text: ['custom'],
        job_title: ['required'],
        work_city: ['required'],
        work_country: ['required']
      },
      countries: [],
      ok_to_query_state: false,
      state_to_query: '',
      form: new SparkForm({
        first_name: '',
        last_name: '',
        phone: '',
        phone_country_code: 'CA',
        email: '',
        email_confirmation: '',
        has_hispanic_latino_or_spanish_origin: null,
        fair: '',
        birthdate: '',
        grad_type: '',
        graduation_year: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        high_school_region: '',
        high_school_country_code: '',
        college_start_semester: null,
        gpa: '',
        gpa_max: '',
        sat_score: '',
        act_score: '',
        email_confirmation_send: true,
        text_confirmation: false,
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        accept_tos: false,
        high_school_ceeb: 0,
        professional_type: '',
        professional_type_text: '',
        job_title: '',
        work_city: '',
        work_state: '',
        work_zip: '',
        work_country: '',
        submitted: false,
        organization: ''
      }),
      phone: '',
      previously_selected_form_field: '',
      currently_selected_form_field: '',
      birthdate: null,
      semesters: [],
      grad_years: [],
      gradeTypes: [],
      professionalTypes: [],
      schoolTypes: [],
      otherKey: '',
      states: STATES,
      provinces: PROVINCES,
      upcomingFairs: [],
      student: student,
      filter_query: null,
      suggested_addresses: [],
      autocompleted: false,
      google_autocomplete: null,
      schoolItems: [],
      selectedSchool: null,
      showHighschoolFields: false,
      isSubmitDisabled: false,
      hasQRSendError: false,
      twilioFailure: null,
      graduation_object: null,
      remember_info: false,
      isHomeSchooled: false,
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropdown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      isProfessionSchoolType: true
    };
  },
  computed: {
    fairIsVirtual() {
      if (this.form.fair) {
        let fair = this.upcomingFairs.find((item) => {
          return item.id === this.form.fair;
        });
        if (fair) {
          return fair.is_virtual;
        }
      }
      return false;
    }
  },
  watch: {
    'form.work_country': function() {
      if (this.student) {
        if (
            this.form.work_country == this.student.student_address.country_code
        ) {
          this.form.work_state = this.student.student_address.region;
        } else {
          this.form.work_state = '';
        }
      } else {
        if (this.form.work_country == 'CA') {
          this.form.work_state = 'ON';
        } else {
          this.form.work_state = '';
        }
      }
    },
    upcomingFairs: function() {
      if (this.upcomingFairs.length === 1) {
        this.form.fair = this.upcomingFairs[0].id;
      }
    },
    autocompleted: function() {
      this.show_address = true;
      if (this.autocompleted) {
        this.validate('address_line_1', this.form.address_line_1);
        this.validate('address_line_2', this.form.address_line_2);
        this.validate('address_city', this.form.address_city);
        this.validate('address_region', this.form.address_region);
        this.validate('address_postal_code', this.form.address_postal_code);
      }
    },
    'form.graduation_year': function() {
      if (this.form && this.form.graduation_year) {
        let currentDate = new Date(),
          currentYear = currentDate.getFullYear(),
          offsetYear = null;

        let borderDate = new Date(`${currentYear}-06-01`);

        if (borderDate < currentDate && this.form.graduation_year === currentYear) {
          offsetYear = currentYear + 1;
          this.form.college_start_semester = `Spring ${offsetYear}`;
        } else {
          this.form.college_start_semester = `Fall ${this.form.graduation_year}`;
        }
        this.remove_form_field_errors('college_start_semester', 'required');
      }
    },
    graduation_object: function() {
      this.form.graduation_year = this.graduation_object.year;
      this.form.grad_type = this.graduation_object.type;

      // clear form errors for graduation_year and grad_type
      this.remove_form_field_errors('grad_type', 'required');
    },
    'form.address_city': function() {
      if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = this.form.address_city;
      }
    },
    'form.address_region': function() {
      if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_region = this.form.address_region;
      }
    },
    'form.high_school': function() {
      if (this.form.high_school_ceeb) {
        this.remove_form_field_errors('high_school', 'required');
      }
    },
    'form.fair': function() {
      $(() => {
        let selectedFair;

        this.querySchoolApi('');

        if (!this.student) {
          selectedFair = this.upcomingFairs.filter((f) => {
            return f.id === this.form.fair;
          });
        } else {
          let fairData = {
            id: this.student.fair.id,
            name: this.student.fair.name
          };
          selectedFair = [fairData];
        }

        if (!selectedFair) return;

        let selectedCountry = this.countries.filter((c) => {
          return c.alpha2 === selectedFair[0].country;
        });

        this.setUpLocativeItemsFromCountry(selectedCountry);
      });
    },
    'form.professional_type': function() {
      // Need to consider english AND french
      const schoolTypes = [
        'Counsellor',
        'Teacher',
        'Principal',
        'Conseillère ou conseiller',
        'Enseignante ou enseignant',
        'Directrice ou directeur'
      ];

      this.isProfessionSchoolType = schoolTypes.indexOf(this.form.professional_type) !== -1;

      let error_message = this.$translate('professional-type');

      if ((this.form.professional_type === 'Other' || this.form.professional_type === 'Autre') && this.form.professional_type_text === '') {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { professional_type: [error_message] });
      } else {
        this.remove_form_field_errors('professional_type', error_message);
      }

      if (
        this.professionalTypes.indexOf(this.form.professional_type) <= this.professionalTypes.length - 2 &&
        this.professionalTypes.indexOf(this.form.professional_type) !== -1
      ) {
        this.form.professional_type_text = '';
      }
    }
  },
  created() {
    this.countries = window.countries;
    this.professionalTypes = window.professionalTypes;
    this.schoolTypes = window.schoolTypes;
    this.otherKey = window.otherKey;
  },
  /**
   * Bootstrap the component.
   */
  mounted() {
    this.isSubmitDisabled = false;

    if (typeof query != 'undefined') {
      this.filter_query = query;
    }

    var rawSemesters = [];
    var now = new Date();

    let currentDate = new Date(),
      currentYear = currentDate.getFullYear();
    let offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    if (borderDate < currentDate) {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + 1 + i;
        rawSemesters.push('Spring ' + offsetYear);
        rawSemesters.push('Fall ' + offsetYear);
      }
    } else {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + i;
        rawSemesters.push('Spring ' + offsetYear);
        rawSemesters.push('Fall ' + offsetYear);
      }
    }

    for (var sem in rawSemesters) {
      if (rawSemesters.hasOwnProperty(sem)) {
        this.semesters.push({
          value: rawSemesters[sem],
          text: rawSemesters[sem]
        });
      }
    }

    for (var i = now.getFullYear() - 3; i <= now.getFullYear() + 5; i++) {
      this.grad_years.push(i);
    }

    let gradeYears = [
      {
        year: this.getGraduationYear(0),
        type: 'year',
        label: `Senior - Class of ${this.getGraduationYear(0)}`
      },
      {
        year: this.getGraduationYear(1),
        type: 'year',
        label: `Junior - Class of ${this.getGraduationYear(1)}`
      },
      {
        year: this.getGraduationYear(2),
        type: 'year',
        label: `Sophomore - Class of ${this.getGraduationYear(2)}`
      },
      {
        year: this.getGraduationYear(3),
        type: 'year',
        label: `Freshman - Class of ${this.getGraduationYear(3)}`
      },
      {
        year: 0,
        type: 'middle_school',
        label: `Middle School`
      },
      {
        year: 0,
        type: 'transfer_student',
        label: `Transfer Student`
      }
    ];

    this.gradeTypes = gradeYears;

    this.setStudentFromCookie();

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;

      $(() => {
        let canada = this.countries.filter((c) => {
          return c.alpha2 === 'CA';
        });
        this.setUpLocativeItemsFromCountry(canada);
      });
    }

    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.getLocation();
    moment.locale(currentLocale);
  },
  methods: {
    setUpLocativeItemsFromCountry(selectedCountry) {
      if (selectedCountry.length) {
        this.form.work_country = selectedCountry[0].alpha2;
        this.form.high_school_country_code = selectedCountry[0].alpha2;

        if (!this.form.phone_country_code || (iti && !iti.getNumber(itiNumberFormats.NATIONAL))) {
          this.form.phone_country_code = selectedCountry[0].alpha2;
          iti.setCountry(selectedCountry[0].alpha2);
        }
      }
    },
    handleFairRadiusOnChanged(event) {
      this.fairRadius = event.target.value;
      if (this.lat && this.lng) {
        let lat, lng;
        lat = this.lat;
        lng = this.lng;
        this.setUpcomingFairs({ lat, lng });
      }
    },
    validate_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = this.$translate('The email must be a valid email address.');
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email', error_message);
      return true;
    },
    validate_email_confirmation(value) {
      let error_message = this.$translate('The email confirmation does not match.');
      if (value !== this.form.email) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email_confirmation: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email_confirmation', error_message);
      return true;
    },
    clearFairValidation() {
      this.$nextTick(() => {
        this.remove_form_field_errors('fair', 'The fair field is required.');
      });
    },
    update_show_address() {
      this.show_address = true;
    },
    setStudentForm(student) {
      // Show fields that usually only show after google auto completion.
      this.autocompleted = true;

      // populate personal information
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email_confirmation = student.email;
      this.form.email = student.email;
      this.form.phone = student.phone;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);

      // populate student information
      if (student.school_id) {
        this.form.high_school = student.high_school;
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_id = student.school_id;
        this.form.high_school_ceeb = student.CEEB;
      }

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      if (student.school_ceeb_code === CEEB_UNLISTED || student.school_ceeb_code === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_country_code = student.high_school_country;
      }

      if (this.professionalTypes.indexOf(student.professional_type) === -1) {
        this.form.professional_type = this.otherKey;
        this.form.professional_type_text = student.professional_type;
      } else {
        this.form.professional_type = student.professional_type;
      }

      this.form.job_title = student.job_title;

      if (student.organization) {
        this.form.organization = student.organization;
      }

      this.form.accept_tos = true;

      if (student.phone && student.phone.indexOf('+1') > -1) {
        this.form.phone = student.phone.replace('+1', '');
      }

      // text me my barcode
      this.form.text_confirmation = student.notify_by_text;
      this.form.work_city = student.student_address.municipality;
      this.form.work_state = student.student_address.region;
      this.form.work_zip = student.student_address.postal_code;
      this.form.work_country = student.student_address.country_code;

      // What event are you attending?
      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;

      if (student.phone_number && student.phone_number.number) {
        this.form.phone = student.phone_number.formatted_number;
        this.form.phone_country_code = student.phone_number.country_code;
        this.phone = student.phone_number.formatted_number;
        $(() => {
          if (student.phone_number) {
            iti.setCountry(student.phone_number.country_code);
            iti.setNumber(student.phone_number.formatted_number);
          }
        });
      }
    },
    setStudentFromCookie() {
      let student = this.$cookies.get(this.cookie_name);
      if (student) {
        // Show fields that usually only show after google auto completion.
        this.autocompleted = true;

        // populate personal information
        this.form.first_name = student.first_name;
        this.form.last_name = student.last_name;
        this.form.email = student.email;
        this.form.email_confirmation = student.email;
        this.form.phone = student.phone;
        this.birthdate = moment(student.birthdate)
          .utc()
          .format('MM/DD/YYYY');
        this.form.birthdate = new Date(this.birthdate);

        // populate student information
        this.form.high_school = student.high_school;
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_id = student.high_school_id;
        this.form.high_school_ceeb = student.high_school_ceeb;

        if (student.school_ceeb_code === CEEB_UNLISTED) {
          this.showHighschoolFields = true;
        }

        if (student.school_ceeb_code === CEEB_UNLISTED || student.school_ceeb_code === CEEB_HOMESCHOOLED) {
          this.form.high_school_city = student.high_school_city;
          this.form.high_school_region = student.high_school_region;
          this.form.high_school_country_code = student.high_school_country;
        }

        if (this.professionalTypes.indexOf(student.professional_type) === -1) {
          this.form.professional_type = this.otherKey;
          this.form.professional_type_text = student.professional_type;
        } else {
          this.form.professional_type = student.professional_type;
        }

        this.form.job_title = student.job_title;

        if (student.organization) {
          this.form.organization = student.organization;
        }

        this.form.accept_tos = true;

        if (student.phone && student.phone.indexOf('+1') > -1) {
          this.form.phone = student.phone.replace('+1', '');
        }

        // text me my barcode
        this.form.text_confirmation = student.notify_by_text;
        this.form.work_city = student.work_city;
        this.form.work_state = student.work_state;
        this.form.work_zip = student.work_zip;
        this.form.work_country = student.work_country;

        // What event are you attending?
        this.upcomingFairs = [student.fair];
        this.form.fair = student.fair;

        this.form.phone = student.phone;
        this.form.phone_country_code = student.phone_country_code;
        this.phone = student.phone;
        $(() => {
          iti.setCountry(student.phone_country_code);
          iti.setNumber(student.phone);
        });
      }
    },
    positionError(error) {
      console.error(error);

      this.loading = false;
      this.ok_to_query_state = true;
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropDown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    },
    getRegionFromGoogleAPIResponse(address_components) {
      for (let i = 0; i < address_components.length; i++) {
        let types = address_components[i].types;
        for (let k = 0; k < types.length; k++) {
          if (types[k] === 'administrative_area_level_1') {
            let inner_component = address_components[i].address_components;
            for (let q = 0; q < inner_component.length; q++) {
              if (this.isValidStateShort(inner_component[q].short_name)) {
                return inner_component[q].short_name;
              }
            }
          }
        }
      }
      return '';
    },
    isValidStateShort(state_short) {
      let is_valid = false;
      for (let i = 0; i < this.states.length; i++) {
        if (this.states[i].abbreviation === state_short || this.states[i].name === state_short) {
          is_valid = true;
        }
      }
      return is_valid;
    },
    setUpcomingFairs(query) {
      this.loading = true;
      query.student_type = 'ONTARIO_COUNSELLOR';

      if (query.lat && query.lng) {
        query.radius = this.fairRadius;
      }

      query.occurrence = 'upcoming';
      query.limit = '999';

      let url = '/api/v2/fairs/students/index';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        url += `?${queryString}`;
      }

      axios
        .get(url)
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }

          if (this.fairRadius === 'unknown' && success.data.meta && success.data.meta.radius) {
            let radius = success.data.meta.radius;
            if (radius <= 50) {
              this.fairRadius = 50;
            } else if (radius > 50 && radius <= 100) {
              this.fairRadius = 100;
            } else if (radius > 100 && radius <= 200) {
              this.fairRadius = 200;
            } else {
              this.fairRadius = 'any';
            }
          }
        })
        .catch((error) => {
          this.upcomingFairs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    signup() {
      if (this.phone) {
        const phone_input_i = $('#intl-phone');
        const phone_number = phone_input_i.val();
        const phone_country_data = iti.getSelectedCountryData();
        this.form.phone = phone_number;
        this.form.phone_country_data = phone_country_data.iso2.toUpperCase();
      } else {
        this.form.phone = '';
      }

      if (this.form.high_school_ceeb === CEEB_UNLISTED || this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = this.form.work_city;
        this.form.high_school_region = this.form.work_state;
        this.form.high_school_country_code = this.form.work_country;
      }

      if (this.fairIsVirtual && this.remember_info) {
        let rememberForm = {};
        Object.assign(rememberForm, this.form);
        delete rememberForm['fair'];
        delete rememberForm['accept_tos'];
        delete rememberForm['authorize_cis'];
        this.$cookies.set(this.cookie_name, JSON.stringify(rememberForm), '1d');
      }

      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      if (
        this.professionalTypes.indexOf(this.form.professional_type) === this.professionalTypes.length - 1 &&
        this.form.professional_type_text
      ) {
        this.form.professional_type = this.form.professional_type_text;
      }

      let data = Object.assign({}, this.form, { phone: this.form.phone.replace(/[^0-9]+/g, '') });

      this.isSubmitDisabled = true;

      this.form.submitted = true;

      let url = '/ontario/professional';
      let method = 'post';
      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      }

      if (typeof currentLocale !== 'undefined' && currentLocale) {
        url = url + '/' + currentLocale;
      }

      const recaptchaInput = document.querySelector('input[name="g-recaptcha-response"]');
      if (recaptchaInput) {
        data['g-recaptcha-response'] = recaptchaInput.value;
      }

      Spark[method](url, data)
        .then((response) => {
          const url = new URL(response.redirect);
          let searchParams = url.searchParams;

          if (this.redirectTo) {
            searchParams.set('redirectTo', this.redirectTo);
          }

          url.search = searchParams.toString();

          const newUrl = url.toString();
          window.location = newUrl;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (this.form.professional_type_text && this.professionalTypes.indexOf(student.professional_type) === -1) {
            this.form.professional_type = this.otherKey;
            if (student.professional_type) {
              this.form.professional_type_text = student.professional_type;
            }
          }

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      if (this.form.fair) {
        query['fair_id'] = this.form.fair;
      }

      this.schoolQuery = search;

      queryURL = '/api/v2/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
          let student = this.$cookies.get(this.cookie_name);
          if (student) {
            let school = data.find((item) => {
              return item.id === student.high_school_id;
            });
            this.selectSchool(school);
          }
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.isHomeSchooled = false;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school_id = school.id;

        if (school.CEEB === CEEB_UNLISTED) {
          this.form.high_school = this.schoolQuery || '';
          this.form.high_school_city = this.form.work_city;
          this.form.high_school_region = this.form.address_region;
          this.form.high_school_country_code = this.form.work_country;

          if (student) {
            this.form.high_school = student.high_school;
            this.form.high_school_city = student.high_school_city;
            this.form.high_school_region = student.high_school_region;
            this.form.high_school_country_code = student.high_school_country;
          }

          this.showHighschoolFields = true;
        } else if (school.CEEB === CEEB_HOMESCHOOLED) {
          this.form.high_school = school.school;
          this.form.high_school_city = this.form.work_city;
          this.form.high_school_region = this.form.address_region;
          this.form.high_school_country_code = this.form.work_country;
          this.showHighschoolFields = false;
          this.isHomeSchooled = true;
        } else {
          this.form.high_school = school.school;
          this.form.high_school_city = school.city;
          this.form.high_school_region = school.state;
          this.form.high_school_country_code = '';
          this.showHighschoolFields = false;
        }
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.isHomeSchooled = false;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';

      this.schoolQuery = '';
      this.querySchoolApi('');
    },
    getGraduationYear(addYears = 0) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        offsetYear = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + addYears + 1;
      } else {
        offsetYear = currentYear + addYears;
      }

      return offsetYear;
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=ONTARIO_COUNSELLOR&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});