import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import STATES from '../constants/states';
import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';
import { getInterests } from '../constants/interests.js';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup-professional', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      redirectTo: urlParams.get('redirectTo'),
      schoolQuery: '',
      cookie_name: 'professional_registration_form',
      loading: false,
      size: '10px',
      color: '#642c8f',
      radius: '50%',
      show_address: true,
      google: require('google-maps-api')(google_api_key, ['places']),
      fields_to_validate: {
        last_name: ['required'],
        first_name: ['required'],
        work_phone: ['required', 'custom'],
        email: ['required', 'custom'],
        email_confirmation: ['required', 'custom'],
        high_school: ['required'],
        birthdate: ['required', 'custom'],
        grad_type: ['custom'],
        act_score: ['custom'],
        sat_score: ['custom'],
        college_start_semester: ['required'],
        fair: ['custom'],
        accept_tos: ['required', 'custom'],
        professional_type: ['required'],
        professional_type_text: ['custom'],
        job_title: ['required'],
        work_address: ['required'],
        work_city: ['custom'],
        work_state: ['required'],
        work_zip: ['required'],
        work_country: ['custom']
      },
      races: [],
      hispanic_latino_spanish_origin: null,
      previously_selected_hispanic_latino_spanish_origin: null,
      ethnicities: [],
      countries: [],
      ok_to_query_state: false,
      state_to_query: '',
      form: new SparkForm({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        email_confirmation: '',
        races: [],
        ethnicities: [],
        has_hispanic_latino_or_spanish_origin: null,
        text_permission: false,
        fair: '',
        birthdate: '',
        grad_type: '',
        graduation_year: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        high_school_region: '',
        high_school_country_code: '',
        college_start_semester: null,
        gpa: '',
        gpa_max: '',
        sat_score: '',
        act_score: '',
        email_confirmation_send: true,
        text_confirmation: true,
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        accept_tos: '',
        high_school_ceeb: 0,
        professional_type: '',
        professional_type_text: '',
        job_title: '',
        pronouns: '',
        pronouns_text: '',
        work_address: '',
        work_address_line_2: '',
        work_city: '',
        work_state: '',
        work_zip: '',
        work_country: '',
        work_phone: '',
        work_phone_ext: '',
        work_phone_country_code: '',
        cell_phone: '',
        cell_phone_country_code: '',
        submitted: false,
        organization: ''
      }),
      work_phone: '',
      cell_phone: '',
      previously_selected_form_field: '',
      currently_selected_form_field: '',
      birthdate: null,
      months: [
        { value: '01', label: this.$translate('January') },
        { value: '02', label: this.$translate('February') },
        { value: '03', label: this.$translate('March') },
        { value: '04', label: this.$translate('April') },
        { value: '05', label: this.$translate('May') },
        { value: '06', label: this.$translate('June') },
        { value: '07', label: this.$translate('July') },
        { value: '08', label: this.$translate('August') },
        { value: '09', label: this.$translate('September') },
        { value: '10', label: this.$translate('October') },
        { value: '11', label: this.$translate('November') },
        { value: '12', label: this.$translate('December') }
      ],
      semesters: [],
      grad_years: [],
      gradeTypes: [],
      interests: getInterests(),
      states: STATES,
      upcomingFairs: [],
      student: student,
      filter_query: null,
      suggested_addresses: [],
      autocompleted: false,
      google_autocomplete: null,
      schoolItems: [],
      interestItems: [],
      selectedSchool: null,
      interestQuery: '',
      selectedInterests: [],
      maxInterests: 3,
      showHighschoolFields: false,
      isSubmitDisabled: false,
      hasQRSendError: false,
      twilioFailure: null,
      graduation_object: null,
      remember_info: false,
      isHomeSchooled: false,
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropdown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      professionalTypes: [
        this.$translate('Secondary/High School - Public'),
        this.$translate('Secondary/High School - Private'),
        this.$translate('College/University - 4 year'),
        this.$translate('College/University - 2 year'),
        this.$translate('Independent Educational Consultant'),
        this.$translate('Organization'),
        this.$translate('Business'),
        this.$translate('Retired'),
        this.$translate('Student'),
        this.$translate('N/A'),
        this.$translate('Other')
      ],
      isProfessionSchoolType: true,
      pronouns: [...new Set([
        this.$translate('she-her-hers'),
        this.$translate('he-him-his'),
        this.$translate('they-them-their'),
        this.$translate('ze-xem-xyr'),
        this.$translate('ze-hir-hirs'),
        this.$translate('prefer-not-to-say'),
        this.$translate('self-describe')
      ])]
    };
  },
  computed: {
    translatedOther() {
        return this.$translate('other');
    },

    fairIsVirtual() {
      if (this.form.fair) {
        let fair = this.upcomingFairs.find((item) => {
          return item.id === this.form.fair;
        });
        if (fair) {
          return fair.is_virtual;
        }
      }
      return false;
    }
  },
  watch: {
    state_to_query: function() {
      if (this.state_to_query != 'show_all') {
        this.setUpcomingFairs({ state: this.state_to_query });
        this.form.fair = '';
      } else {
        this.upcomingFairs = fairs;
      }
    },
    upcomingFairs: function() {
      if (this.upcomingFairs.length === 1) {
        this.form.fair = this.upcomingFairs[0].id;
      }
    },
    autocompleted: function() {
      this.show_address = true;
      if (this.autocompleted) {
        this.validate('address_line_1', this.form.address_line_1);
        this.validate('address_line_2', this.form.address_line_2);
        this.validate('address_city', this.form.address_city);
        this.validate('address_region', this.form.address_region);
        this.validate('address_postal_code', this.form.address_postal_code);
      }
    },
    hispanic_latino_spanish_origin: function() {
      if (this.hispanic_latino_spanish_origin === 'true') {
        this.form.has_hispanic_latino_or_spanish_origin = true;

        if (this.form.ethnicities.length < 1) {
          if (this.form.errors.errors['ethnicities']) {
            this.form.errors.errors['ethnicities'] = [
              this.$translate('The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.')
            ];
          } else {
            this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
              ['ethnicities']: [this.$translate('The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.')]
            });
          }
        }
      } else {
        this.form.has_hispanic_latino_or_spanish_origin = this.hispanic_latino_spanish_origin === 'false' ? false : null;
        this.ethnicities = [];
        // clear out form errors
        Vue.delete(this.form.errors.errors, 'ethnicities');
      }
    },
    ethnicities: function() {
      let results = [];
      for (let i = 0; i < this.ethnicities.length; i++) {
        results.push({ ethnicity: this.ethnicities[i] });
      }
      this.form.ethnicities = results;

      if (this.form.ethnicities.length > 0) {
        Vue.delete(this.form.errors.errors, 'ethnicities');
      } else if (this.form.ethnicities.length <= 0 && this.form.has_hispanic_latino_or_spanish_origin) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          ['ethnicities']: [this.$translate('The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.')]
        });
      }
    },
    races: function() {
      let results = [];
      for (let i = 0; i < this.races.length; i++) {
        results.push({ race: this.races[i] });
      }
      this.form.races = results;
    },
    'form.accept_tos': function() {
      let error_message = this.$translate('You must agree before continuing.');
      if (!this.form.accept_tos) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { accept_tos: [error_message] });
      } else {
        this.remove_form_field_errors('accept_tos', error_message);
      }
    },
    'form.graduation_year': function() {
      if (this.form && this.form.graduation_year) {
        let currentDate = new Date(),
          currentYear = currentDate.getFullYear(),
          offsetYear = null;

        let borderDate = new Date(`${currentYear}-06-01`);

        if (borderDate < currentDate && this.form.graduation_year === currentYear) {
          offsetYear = currentYear + 1;
          this.form.college_start_semester = `Spring ${offsetYear}`;
        } else {
          this.form.college_start_semester = `Fall ${this.form.graduation_year}`;
        }
        this.remove_form_field_errors('college_start_semester', 'required');
      }
    },
    graduation_object: function() {
      this.form.graduation_year = this.graduation_object.year;
      this.form.grad_type = this.graduation_object.type;

      // clear form errors for graduation_year and grad_type
      this.remove_form_field_errors('grad_type', 'required');
    },
    'form.address_city': function() {
      if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = this.form.address_city;
      }
    },
    'form.address_region': function() {
      if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_region = this.form.address_region;
      }
    },
    'form.high_school': function() {
      if (this.form.high_school_ceeb) {
        this.remove_form_field_errors('high_school', 'required');
      }
    },
    'form.fair': function() {
      let error_message = this.$translate('the-event-field-is-required');
      if (!this.form.fair) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { fair: [error_message] });
      } else {
        this.remove_form_field_errors('fair', error_message);
      }
      this.querySchoolApi('');

      let selectedFair;

      if (!this.student) {
        selectedFair = this.upcomingFairs.filter((f) => {
          return f.id === this.form.fair;
        });
      } else {
        let fairData = {
          id: this.student.fair.id,
          name: this.student.fair.name,
          country: this.student.fair.venue.address.country_code
        };
        selectedFair = [fairData];
      }

      if (!selectedFair) return;

      let selectedCountry = this.countries.filter((c) => {
        return c.alpha2 === selectedFair[0].country;
      });

      if (selectedCountry.length) {
        this.form.work_country = selectedCountry[0].alpha2;
        this.form.high_school_country_code = selectedCountry[0].alpha2;

        if (cell_phone_iti && !cell_phone_iti.getNumber(itiNumberFormats.NATIONAL)) {
          this.form.cell_phone_country_code = selectedCountry[0].alpha2;
          cell_phone_iti.setCountry(selectedCountry[0].alpha2);
        }

        if (work_phone_iti && !work_phone_iti.getNumber(itiNumberFormats.NATIONAL)) {
          this.form.work_phone_country_code = selectedCountry[0].alpha2;
          work_phone_iti.setCountry(selectedCountry[0].alpha2);
        }
      }
    },
    'form.professional_type': function() {
      const schoolTypes = [
        this.$translate('Secondary/High School - Public'), 
        this.$translate('Secondary/High School - Private'), 
        this.$translate('College/University - 2 year')
      ];

      this.isProfessionSchoolType = schoolTypes.indexOf(this.form.professional_type) !== -1;

      let error_message = this.$translate('the-professional-type-field-is-required');

      if (this.form.professional_type === this.$translate('other') && this.form.professional_type_text === '') {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { professional_type: [error_message] });
      } else {
        this.remove_form_field_errors('professional_type', error_message);
      }

      if (
        this.professionalTypes.indexOf(this.form.professional_type) <= this.professionalTypes.length - 2 &&
        this.professionalTypes.indexOf(this.form.professional_type) !== -1
      ) {
        this.form.professional_type_text = '';
      }
    }
  },

  created() {
    this.countries = window.countries;
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.isSubmitDisabled = false;
    if (typeof query != 'undefined') {
      this.filter_query = query;
    }

    var rawSemesters = [];
    var now = new Date();

    let currentDate = new Date(),
      currentYear = currentDate.getFullYear();
    let offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    var spring = this.$translate('Spring');
    var fall = this.$translate('Fall');

    if (borderDate < currentDate) {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + 1 + i;
        rawSemesters.push(spring + ' ' + offsetYear);
        rawSemesters.push(fall + ' ' + offsetYear);
      }
    } else {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + i;
        rawSemesters.push(spring + ' ' + offsetYear);
        rawSemesters.push(fall + ' ' + offsetYear);
      }
    }

    for (var sem in rawSemesters) {
      if (rawSemesters.hasOwnProperty(sem)) {
        this.semesters.push({
          value: rawSemesters[sem],
          text: rawSemesters[sem]
        });
      }
    }

    for (var i = now.getFullYear() - 3; i <= now.getFullYear() + 5; i++) {
      this.grad_years.push(i);
    }

    let gradeYears = [
      {
        year: this.getGraduationYear(0),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Senior'), year: this.getGraduationYear(0) })
      },
      {
        year: this.getGraduationYear(1),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Junior'), year: this.getGraduationYear(1) })
      },
      {
        year: this.getGraduationYear(2),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Sophomore'), year: this.getGraduationYear(2) })
      },
      {
        year: this.getGraduationYear(3),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Freshmen'), year: this.getGraduationYear(3) })
      },
      {
        year: 0,
        type: 'middle_school',
        label: this.$translate('Middle School')
      },
      {
        year: 0,
        type: 'transfer_student',
        label: this.$translate('Transfer Student')
      }
    ];

    this.gradeTypes = gradeYears;

    this.resetInterests();

    this.setStudentFromCookie();

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;
    }

    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.getLocation();

    var app = this;
    app.google().then(function(maps) {
      app.google_autocomplete = new maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['geocode'],
        fields: ['address_components']
      });
      app.google_autocomplete.setFields(['address_components', 'name']);
      app.google_autocomplete.addListener('place_changed', app.fillInAddress);
    });
  },
  methods: {
    handleFairRadiusOnChanged(event) {
      this.fairRadius = event.target.value;

      if (this.lat && this.lng) {
        let lat, lng;
        lat = this.lat;
        lng = this.lng;
        this.setUpcomingFairs({ lat, lng });
      }
    },
    validate_work_phone(value) {
      let regex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
      let error_message = this.$translate('The phone must be 10 characters.');
      if (value && !regex.test(value)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { phone: [error_message] });
        return false;
      }
      this.remove_form_field_errors('work_phone', error_message);
      return true;
    },
    validate_sat_score(value) {
      let error_message = this.$translate('The SAT score must be between 200 and 1600');
      if (value && (value < 200 || value > 1600)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { sat_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('sat_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_act_score(value) {
      let error_message = this.$translate('The ACT score must be between 1 and 36.');
      if (value && (value < 1 || value > 36)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { act_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('act_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = this.$translate('The email must be a valid email address.');
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email', error_message);
      return true;
    },
    validate_email_confirmation(value) {
      let error_message = this.$translate('The email confirmation does not match.');
      if (value !== this.form.email) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email_confirmation: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email_confirmation', error_message);
      return true;
    },
    validate_birthdate(value) {
      let regex = /(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}/;
      let error_message = this.$translate('Birthdate must be in the format `MM/DD/YYYY`');
      if (value && !regex.test(value)) {
        if (this.form.errors.errors['birthdate']) {
          this.form.errors.errors['birthdate'].push(error_message);
        } else {
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, { birthdate: [error_message] });
        }
        return false;
      } else if (value) {
        this.form.birthdate = new Date(value);
      }
      this.remove_form_field_errors('birthdate', error_message);
      return true;
    },
    validate_grad_type(value) {
      const errorMessage = this.$translate('The grade field is required.');

      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { grad_type: [errorMessage] });
        return false;
      }

      return true;
    },
    validate_fair(value) {
      let error_message = this.$translate('the-event-field-is-required');
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { fair: [error_message] });
      } else {
        this.remove_form_field_errors('fair', error_message);
      }
    },
    validate_work_city(value) {
      let error_message = this.$translate('the-work-city-field-is-required');

      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { work_city: [error_message] });
      } else {
        this.remove_form_field_errors('work_city', error_message);
      }
    },
    validate_work_state(value) {
      let error_message = this.$translate('the-state-province-region-field-is-required');

      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { work_state: [error_message] });
      } else {
        this.remove_form_field_errors('work_state', error_message);
      }
    },
    validate_work_zip(value) {
      let error_message = this.$translate('the-zip-postal-code-field-is-required');

      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { work_zip: [error_message] });
      } else {
        this.remove_form_field_errors('work_zip', error_message);
      }
    },
    validate_work_country(value) {
      let error_message = this.$translate('the-country-field-is-required');

      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { work_country: [error_message] });
      } else {
        this.remove_form_field_errors('work_country', error_message);
      }
    },
    uncheck_ethnicity_radio_buttons(value) {
      this.hispanic_latino_spanish_origin = value;
      if (value == this.previously_selected_hispanic_latino_spanish_origin) {
        this.hispanic_latino_spanish_origin = null;
      }
      this.previously_selected_hispanic_latino_spanish_origin = this.hispanic_latino_spanish_origin;
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=PROFESSIONAL&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropdown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    },
    clearFairValidation() {
      this.$nextTick(() => {
        this.remove_form_field_errors('fair', this.$translate('The fair field is required.'));
      });
    },
    update_show_address() {
      this.show_address = true;
    },
    setStudentForm(student) {
      // Show fields that usually only show after google auto completion.
      this.autocompleted = true;

      // populate personal information
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.form.cell_phone = student.phone;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);

      // populate student information
      if (student.school_id) {
        this.form.high_school = student.high_school;
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_id = student.school_id;
        this.form.high_school_ceeb = student.CEEB;
      }

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      if (student.school_ceeb_code === CEEB_UNLISTED || student.school_ceeb_code === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_country_code = student.high_school_country;
      }

      if (this.professionalTypes.indexOf(this.$translate(student.professional_type)) === -1) {
        this.form.professional_type = this.$translate('other');
        this.form.professional_type_text = this.$translate(student.professional_type);
      } else {
        this.form.professional_type = this.$translate(student.professional_type);
      }

      if (this.pronouns.indexOf(this.$translate(student.pronouns)) === -1 && student.pronouns !== '') {
        this.form.pronouns = this.$translate('self-describe');
        this.form.pronouns_text = this.$translate(student.pronouns);
      } else {
        this.form.pronouns = this.$translate(student.pronouns);
      }

      this.form.job_title = student.job_title;

      if (student.organization) {
        this.form.organization = student.organization;
      }

      this.form.accept_tos = true;

      // text permission
      this.form.text_permission = student.text_permission;

      this.resetInterestPositions();
      this.resetInterests();

      if (student.phone && student.phone.indexOf('+1') > -1) {
        this.form.cell_phone = student.phone.replace('+1', '');
      }

      // text me my barcode
      this.form.text_confirmation = student.notify_by_text;
      this.form.work_address = student.student_address.line1;
      this.form.work_address_line_2 = student.student_address.line2;
      this.form.work_city = student.student_address.municipality;
      this.form.work_state = student.student_address.region;
      this.form.work_zip = student.student_address.postal_code;
      this.form.work_country = student.student_address.country_code;

      // What event are you attending?
      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;

      if (student.phone_number && student.phone_number.number) {
        this.form.cell_phone = student.phone_number.formatted_number;
        this.form.cell_phone_country_code = student.phone_number.country_code;
        this.cell_phone = student.phone_number.formatted_number;
        $(() => {
          if (student.phone_number) {
            cell_phone_iti.setCountry(student.phone_number.country_code);
            cell_phone_iti.setNumber(student.phone_number.formatted_number);
          }
        });
      }

      this.form.work_phone = student.work_phone;
      this.form.work_phone_ext = student.work_phone_ext;
      this.form.work_phone_country_code = student.work_phone_country_code;
      this.work_phone = student.work_phone;
      $(() => {
        if (student.work_phone) {
          work_phone_iti.setCountry(student.work_phone_country_code);
          work_phone_iti.setNumber(student.work_phone);
        }
      });
    },
    setStudentFromCookie() {
      let student = this.$cookies.get(this.cookie_name);
      if (student) {
        // Show fields that usually only show after google auto completion.
        this.autocompleted = true;

        // populate personal information
        this.form.first_name = student.first_name;
        this.form.last_name = student.last_name;
        this.form.email = student.email;
        this.form.email_confirmation = student.email;
        this.form.cell_phone = student.cell_phone;
        this.birthdate = moment(student.birthdate)
          .utc()
          .format('MM/DD/YYYY');
        this.form.birthdate = new Date(this.birthdate);

        // populate student information
        this.form.high_school = student.high_school;
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_id = student.high_school_id;
        this.form.high_school_ceeb = student.high_school_ceeb;

        if (student.school_ceeb_code === CEEB_UNLISTED) {
          this.showHighschoolFields = true;
        }

        if (student.school_ceeb_code === CEEB_UNLISTED || student.school_ceeb_code === CEEB_HOMESCHOOLED) {
          this.form.high_school_city = student.high_school_city;
          this.form.high_school_region = student.high_school_region;
          this.form.high_school_country_code = student.high_school_country;
        }

        if (this.professionalTypes.indexOf(this.$translate(student.professional_type)) === -1) {
          this.form.professional_type = this.$translate('other');
          this.form.professional_type_text = this.$translate(student.professional_type);
        } else {
          this.form.professional_type = this.$translate(student.professional_type);
        }

        if (this.pronouns.indexOf(student.pronouns) === -1 && student.pronouns !== '') {
          this.form.pronouns = this.$translate('self-describe');
          this.form.pronouns_text = this.$translate(student.pronouns);
        } else {
          this.form.pronouns = this.$translate(student.pronouns);
        }

        this.form.job_title = student.job_title;

        if (student.organization) {
          this.form.organization = student.organization;
        }

        this.form.accept_tos = true;

        // text permission
        this.form.text_permission = student.text_permission;

        this.resetInterestPositions();
        this.resetInterests();

        if (student.cell_phone && student.cell_phone.indexOf('+1') > -1) {
          this.form.cell_phone = student.cell_phone.replace('+1', '');
        }

        // text me my barcode
        this.form.text_confirmation = student.notify_by_text;
        this.form.work_address = student.work_address;
        this.form.work_address_line_2 = student.work_address_line_2;
        this.form.work_city = student.work_city;
        this.form.work_state = student.work_state;
        this.form.work_zip = student.work_zip;
        this.form.work_country = student.work_country;

        // What event are you attending?
        this.upcomingFairs = [student.fair];
        this.form.fair = student.fair;

        this.form.cell_phone = student.cell_phone;
        this.form.cell_phone_country_code = student.cell_phone_country_code;
        this.cell_phone = student.cell_phone;
        $(() => {
          cell_phone_iti.setCountry(student.cell_phone_country_code);
          cell_phone_iti.setNumber(student.cell_phone);
        });

        this.form.work_phone = student.work_phone;
        this.form.work_phone_ext = student.work_phone_ext;
        this.form.work_phone_country_code = student.work_phone_country_code;
        this.work_phone = student.work_phone;
        $(() => {
          if (student.work_phone) {
            work_phone_iti.setCountry(student.work_phone_country_code);
            work_phone_iti.setNumber(student.work_phone);
          }
        });
      }
    },
    fillInAddress() {
      var place = this.google_autocomplete.getPlace();

      let fields = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'long_name',
        postal_code_suffix: 'long_name',
        country: 'short_name'
      };

      for (let i = 0; i < place.address_components.length; i++) {
        let address_type = place.address_components[i].types[0];

        fields[address_type] = place.address_components[i][fields[address_type]];
      }

      let street_number = fields.street_number !== 'short_name' ? fields.street_number : '';
      let route_name = fields.route !== 'long_name' ? fields.route : '';
      let street_number_and_route_name = '';
      street_number_and_route_name =
        street_number && route_name ? street_number + ' ' + route_name : street_number ? street_number : route_name;
      document.getElementById('autocomplete').value = street_number_and_route_name;
      this.form.work_address = street_number_and_route_name;

      /*
                Brooklyn and other parts of New York City do not include the city as part of the address.
                They use sublocality_level_1 instead.
                @see https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
             */
      let locality = fields.locality !== 'long_name' ? fields.locality : '';
      if (!locality) {
        locality = fields.sublocality_level_1 !== 'long_name' ? fields.sublocality_level_1 : '';
      }
      document.getElementById('locality').value = locality;
      this.form.work_city = locality;

      let administrative_area_level_1 = fields.administrative_area_level_1 !== 'short_name' ? fields.administrative_area_level_1 : '';
      document.getElementById('administrative_area_level_1').value = administrative_area_level_1;
      this.form.work_state = administrative_area_level_1;

      // Not every address has a postal code suffix
      let postal_code = fields.postal_code !== 'long_name' ? fields.postal_code : '';
      let postal_code_suffix = fields.postal_code_suffix !== 'long_name' ? fields.postal_code_suffix : '';
      let post_code_and_suffix =
        postal_code && postal_code_suffix ? postal_code + '-' + postal_code_suffix : postal_code ? postal_code : postal_code_suffix;
      document.getElementById('address_postal_code').value = post_code_and_suffix;
      this.form.work_zip = post_code_and_suffix;

      let country = fields.country !== 'short_name' ? fields.country : '';
      document.getElementById('work_country').value = work_country;
      this.form.work_country = country;

      this.autocompleted = true;
    },
    positionError(error) {
      console.error(error);
      this.loading = false;
      this.ok_to_query_state = true;
    },
    getFairsInRegion(position) {
      let coordinates = position.coords;
      let lat = coordinates.latitude;
      let lng = coordinates.longitude;

      this.showDistanceDropdown = true;

      if (lat && lng) {
        this.lat = lat;
        this.lng = lng;
        this.setUpcomingFairs({ lat, lng });
      } else {
        this.upcomingFairs = [];
      }
    },
    getRegionFromGoogleAPIResponse(address_components) {
      for (let i = 0; i < address_components.length; i++) {
        let types = address_components[i].types;
        for (let k = 0; k < types.length; k++) {
          if (types[k] === 'administrative_area_level_1') {
            let inner_component = address_components[i].address_components;
            for (let q = 0; q < inner_component.length; q++) {
              if (this.isValidStateShort(inner_component[q].short_name)) {
                return inner_component[q].short_name;
              }
            }
          }
        }
      }
      return '';
    },
    isValidStateShort(state_short) {
      let is_valid = false;
      for (let i = 0; i < this.states.length; i++) {
        if (this.states[i].abbreviation === state_short || this.states[i].name === state_short) {
          is_valid = true;
        }
      }
      return is_valid;
    },
    setUpcomingFairs(query) {
      this.loading = true;
      query.student_type = 'PROFESSIONAL';

      if (query.lat && query.lng) {
        query.radius = this.fairRadius;
      }

      query.occurrence = 'upcoming';
      query.limit = '999';

      let url = '/api/v2/fairs/students/index';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        url += `?${queryString}`;
      }

      axios
        .get(url)
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }

          if (this.fairRadius === 'unknown' && success.data.meta && success.data.meta.radius) {
            let radius = success.data.meta.radius;
            if (radius <= 5) {
              this.fairRadius = 5;
            } else if (radius > 5 && radius <= 25) {
              this.fairRadius = 25;
            } else if (radius > 25 && radius <= 50) {
              this.fairRadius = 50;
            } else {
              this.fairRadius = 'any';
            }
          }
        })
        .catch((error) => {
          this.upcomingFairs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    signup() {
      if (this.work_phone) {
        const work_phone_input_i = $('#work_phone');
        const work_phone_number = work_phone_input_i.val();
        const work_phone_country_data = work_phone_iti.getSelectedCountryData();
        this.form.work_phone = work_phone_number;
        this.form.work_phone_country_code = work_phone_country_data.iso2.toUpperCase();
      }

      if (this.cell_phone) {
        const cell_phone_input_i = $('#cell_phone');
        const cell_phone_number = cell_phone_input_i.val();
        const cell_phone_country_data = cell_phone_iti.getSelectedCountryData();
        this.form.cell_phone = cell_phone_number;
        this.form.cell_phone_country_code = cell_phone_country_data.iso2.toUpperCase();
      } else {
        this.form.cell_phone = '';
      }

      if (this.form.high_school_ceeb === CEEB_UNLISTED || this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = this.form.work_city;
        this.form.high_school_region = this.form.work_state;
        this.form.high_school_country_code = this.form.work_country;
      }

      if (this.fairIsVirtual && this.remember_info) {
        let rememberForm = {};
        Object.assign(rememberForm, this.form);
        delete rememberForm['fair'];
        delete rememberForm['accept_tos'];
        delete rememberForm['authorize_cis'];
        this.$cookies.set(this.cookie_name, JSON.stringify(rememberForm), '1d');
      }

      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      if (this.form.pronouns_text) {
        this.form.pronouns = this.form.pronouns_text;
      }

      if (
        this.professionalTypes.indexOf(this.form.professional_type) === this.professionalTypes.length - 1 &&
        this.form.professional_type_text
      ) {
        this.form.professional_type = this.form.professional_type_text;
      }

      let data = Object.assign({}, this.form, { work_phone: this.form.work_phone.replace(/[^0-9]+/g, '') });
      data = Object.assign({}, this.form, { cell_phone: this.form.cell_phone.replace(/[^0-9]+/g, '') });

      this.isSubmitDisabled = true;

      this.form.submitted = true;

      let url = '/professional';
      let method = 'post';
      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      } else if (typeof currentLocale !== 'undefined' && currentLocale !== 'en') {
        url += `/${currentLocale}`;
      }

      const recaptchaInput = document.querySelector('input[name="g-recaptcha-response"]');
      if (recaptchaInput) {
        data['g-recaptcha-response'] = recaptchaInput.value;
      }

      Spark[method](url, data)
        .then((response) => {
          const url = new URL(response.redirect);
          let searchParams = url.searchParams;

          if (this.redirectTo) {
            searchParams.set('redirectTo', this.redirectTo);
          }

          url.search = searchParams.toString();

          const newUrl = url.toString();
          window.location = newUrl;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (this.form.professional_type_text && this.professionalTypes.indexOf(this.$translate(student.professional_type)) === -1) {
            this.form.professional_type = this.$translate('other');
            if (student.professional_type) {
              this.form.professional_type_text = this.$translate(student.professional_type);
            }
          }

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      if (this.form.fair) {
        query['fair_id'] = this.form.fair;
      }

      this.schoolQuery = search;

      queryURL = '/api/v2/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
          let student = this.$cookies.get(this.cookie_name);
          if (student) {
            let school = data.find((item) => {
              return item.id === student.high_school_id;
            });
            this.selectSchool(school);
          }
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.isHomeSchooled = false;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school_id = school.id;

        if (school.CEEB === CEEB_UNLISTED) {
          this.form.high_school = this.schoolQuery || '';
          this.form.high_school_city = this.form.work_city;
          this.form.high_school_region = this.form.address_region;
          this.form.high_school_country_code = this.form.work_country;

          if (student) {
            this.form.high_school = student.high_school;
            this.form.high_school_city = student.high_school_city;
            this.form.high_school_region = student.high_school_region;
            this.form.high_school_country_code = student.high_school_country;
          }

          this.showHighschoolFields = true;
        } else if (school.CEEB === CEEB_HOMESCHOOLED) {
          this.form.high_school = school.school;
          this.form.high_school_city = this.form.work_city;
          this.form.high_school_region = this.form.address_region;
          this.form.high_school_country_code = this.form.work_country;
          this.showHighschoolFields = false;
          this.isHomeSchooled = true;
        } else {
          this.form.high_school = school.school;
          this.form.high_school_city = school.city;
          this.form.high_school_region = school.state;
          this.form.high_school_country_code = '';
          this.showHighschoolFields = false;
        }
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.isHomeSchooled = false;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';

      this.schoolQuery = '';
      this.querySchoolApi('');
    },
    resetInterests() {
      let interests = [];

      this.interests.forEach((i, index) => {
        i.children.forEach((ii) => {
          interests.push({
            value: ii
          });
        });
      });

      // Computed differences so they won't show up again when selected
      interests = differenceBy(interests, this.selectedInterests, 'value');

      this.interestItems = interests;

      if (this.$refs.interestRef) {
        this.$refs.interestRef.inputValue = '';
      }
    },
    resetInterestPositions() {
      this.form.area_of_interest_1 = this.selectedInterests[0] ? this.selectedInterests[0].value : '';
      this.form.area_of_interest_2 = this.selectedInterests[1] ? this.selectedInterests[1].value : '';
      this.form.area_of_interest_3 = this.selectedInterests[2] ? this.selectedInterests[2].value : '';
    },
    queryInterests(query) {
      let interests = [];

      this.resetInterests();

      this.interestItems.filter((str) => {
        if (str.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          interests.push({
            value: str.value
          });
        }
      });

      this.interestItems = interests;
    },
    selectInterest(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedInterests.length < this.maxInterests) {
        this.selectedInterests.push(selectedObj);

        this.$nextTick(() => {
          let input = $('#interests-search input');
          input.val('');
          input.blur();

          if (this.selectedInterests.length >= 3) {
            input.hide();
          }
        });

        this.resetInterestPositions();
      }

      this.resetInterests();
    },
    removeInterest(index) {
      this.selectedInterests.splice(index, 1);
      this.resetInterestPositions();

      this.resetInterests();

      let input = $('#interests-search input');

      if (this.selectedInterests.length < 3) {
        input.show();
      }
    },
    getGraduationYear(addYears = 0) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        offsetYear = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + addYears + 1;
      } else {
        offsetYear = currentYear + addYears;
      }

      return offsetYear;
    }
  }
});
