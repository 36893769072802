import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';
import STATES from '../constants/states';
import { getInterests } from '../constants/interests.js';
import {
  stateToQueryWatcher,
  upcomingFairsWatcher,
  autocompletedWatcher,
  hispanicLatinoSpanishOriginWatcher,
  ethnicitiesWatcher,
  racesWatcher,
  acceptTosWatcher,
  graduationYearWatcher,
  graduationObjectWatcher,
  addressCityWatcher,
  addressRegionWatcher,
  highSchoolWatcher
} from '../students/watchers.js';
import {
  handleFairRadiusOnChangedMethod,
  validateSATScoreMethod,
  validateACTScoreMethod,
  validateEmailMethod,
  validateBirthdateMethod,
  validateGradTypeMethod,
  clearFairValidationMethod,
  getFairsInRegionMethod,
  setUpcomingFairsMethod
} from '../students/methods.js';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      studentType: 'USA',
      radiusLimits: [5, 25, 50],
      redirectTo: urlParams.get('redirectTo'),
      cookie_name: 'students_registration_form',
      selectedFair: null,
      schoolQuery: '',
      loading: false,
      size: '10px',
      color: '#642c8f',
      radius: '50%',
      show_address: true,
      remember_confirmation: false,
      rememberd_high_school_id: null,
      google: require('google-maps-api')(google_api_key, ['places']),
      fields_to_validate: {
        last_name: ['required'],
        first_name: ['required'],
        phone: ['required', 'custom'],
        email: ['required', 'custom'],
        confirm_email: ['required', 'custom'],
        high_school: ['required'],
        address_line_1: ['required'],
        address_city: ['required'],
        address_region: ['required'],
        address_postal_code: ['required'],
        birthdate: ['required', 'custom'],
        grad_type: ['custom'],
        act_score: ['custom'],
        sat_score: ['custom'],
        college_start_semester: ['required'],
        fair: ['required'],
        accept_tos: ['required', 'custom']
      },
      races: [],
      hispanic_latino_spanish_origin: null,
      previously_selected_hispanic_latino_spanish_origin: null,
      ethnicities: [],
      ok_to_query_state: false,
      state_to_query: '',
      form: new SparkForm({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        confirm_email: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_region: '',
        address_postal_code: '',
        races: [],
        ethnicities: [],
        has_hispanic_latino_or_spanish_origin: null,
        text_permission: true,
        fair: '',
        birthdate: '',
        grad_type: '',
        graduation_year: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        high_school_region: '',
        college_start_semester: null,
        gpa: '',
        gpa_max: '',
        sat_score: '',
        act_score: '',
        email_confirmation: true,
        text_confirmation: true,
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        accept_tos: '',
        high_school_ceeb: 0,
        'g-recaptcha-response': ''
      }),
      previously_selected_form_field: '',
      currently_selected_form_field: '',
      birthdate: null,
      months: [
        { value: 0, text: this.$translate('January') },
        { value: 1, text: this.$translate('February') },
        { value: 2, text: this.$translate('March') },
        { value: 3, text: this.$translate('April') },
        { value: 4, text: this.$translate('May') },
        { value: 5, text: this.$translate('June') },
        { value: 6, text: this.$translate('July') },
        { value: 7, text: this.$translate('August') },
        { value: 8, text: this.$translate('September') },
        { value: 9, text: this.$translate('October') },
        { value: 10, text: this.$translate('November') },
        { value: 11, text: this.$translate('December') }
      ],
      semesters: [],
      grad_years: [],
      gradeTypes: [],
      interests: getInterests(),
      states: STATES,
      upcomingFairs: [],
      student: student,
      filter_query: null,
      suggested_addresses: [],
      autocompleted: false,
      google_autocomplete: null,
      schoolItems: [],
      interestItems: [],
      selectedSchool: null,
      previousSelectedSchool: null,
      interestQuery: '',
      selectedInterests: [],
      maxInterests: 3,
      showHighschoolFields: false,
      isSubmitDisabled: false,
      hasQRSendError: false,
      twilioFailure: null,
      graduation_object: null,
      isHomeSchooled: false,
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropdown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      }
    };
  },
  computed: {
    isVirtual() {
      if (this.form.fair) {
        let fair = this.upcomingFairs.find((item) => {
          return item.id === this.form.fair;
        });
        if (fair) {
          return fair.is_virtual;
        }
      }
      return false;
    }
  },
  watch: {
    state_to_query: stateToQueryWatcher,
    upcomingFairs: upcomingFairsWatcher,
    autocompleted: autocompletedWatcher,
    hispanic_latino_spanish_origin: hispanicLatinoSpanishOriginWatcher,
    ethnicities: ethnicitiesWatcher,
    races: racesWatcher,
    'form.accept_tos': acceptTosWatcher,
    'form.graduation_year': graduationYearWatcher,
    graduation_object: graduationObjectWatcher,
    'form.address_city': addressCityWatcher,
    'form.address_region': addressRegionWatcher,
    'form.high_school': highSchoolWatcher,
    'form.fair': function() {
      this.selectedFair = this.upcomingFairs.find((fair) => fair.id === this.form.fair);
      this.querySchoolApi('');
    }
  },

  created() {},

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.isSubmitDisabled = false;
    if (typeof query != 'undefined') {
      this.filter_query = query;
      this.state_to_query = query.state || '';
    }

    var rawSemesters = [];
    var now = new Date();

    let currentDate = new Date(),
      currentYear = currentDate.getFullYear();
    let offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    var spring = this.$translate('Spring');
    var fall = this.$translate('Fall');

    if (borderDate < currentDate) {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + 1 + i;
        rawSemesters.push(spring + ' ' + offsetYear);
        rawSemesters.push(fall + ' ' + offsetYear);
      }
    } else {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + i;
        rawSemesters.push(spring + ' ' + offsetYear);
        rawSemesters.push(fall + ' ' + offsetYear);
      }
    }

    for (var sem in rawSemesters) {
      if (rawSemesters.hasOwnProperty(sem)) {
        this.semesters.push({
          value: rawSemesters[sem],
          text: rawSemesters[sem]
        });
      }
    }

    for (var i = now.getFullYear() - 3; i <= now.getFullYear() + 5; i++) {
      this.grad_years.push(i);
    }

    let gradeYears = [
      {
        year: this.getGraduationYear(0),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Senior'), year: this.getGraduationYear(0) })
      },
      {
        year: this.getGraduationYear(1),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Junior'), year: this.getGraduationYear(1) })
      },
      {
        year: this.getGraduationYear(2),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Sophomore'), year: this.getGraduationYear(2) })
      },
      {
        year: this.getGraduationYear(3),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Freshman'), year: this.getGraduationYear(3) })
      },
      {
        year: 0,
        type: 'middle_school',
        label: this.$translate('Middle School')
      },
      {
        year: 0,
        type: 'transfer_student',
        label: this.$translate('Transfer Student')
      }
    ];

    this.gradeTypes = gradeYears;

    this.resetInterests();

    this.restoreFromCookie();

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;
    }

    this.getLocation();

    var app = this;
    app.google().then(function(maps) {
      app.google_autocomplete = new maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['geocode'],
        fields: ['address_components']
      });
      app.google_autocomplete.setFields(['address_components', 'name']);
      app.google_autocomplete.addListener('place_changed', app.fillInAddress);
    });
  },
  methods: {
    handleFairRadiusOnChanged: handleFairRadiusOnChangedMethod,
    restoreFromCookie() {
      let cookieInfo = this.$cookies.get(this.cookie_name);
      if (cookieInfo) {
        for (let key in cookieInfo) {
          if (key !== 'errors' && key !== 'school') {
            this.form[key] = cookieInfo[key];
          }
        }
        this.birthdate = moment(this.form.birthdate)
          .utc()
          .format('MM/DD/YYYY');
        this.form.birthdate = new Date(this.birthdate);
        this.hispanic_latino_spanish_origin = this.form.has_hispanic_latino_or_spanish_origin;
        this.races = this.form.races.map((race) => race.race);
        this.ethnicities = this.form.ethnicities.map((ethnicity) => ethnicity.ethnicity);
        const interestArray = [this.form.area_of_interest_1, this.form.area_of_interest_2, this.form.area_of_interest_3];
        for (let index in interestArray) {
          const interestObject = this.interestItems.find((item) => item.value === interestArray[index]);
          if (interestObject) {
            this.selectInterest(interestObject);
          }
        }
        const graduation_object = this.gradeTypes.find(
          (item) => item.type === this.form.grad_type && item.year === this.form.graduation_year
        );
        this.graduation_object = graduation_object;

        // this is a hack, to ensure we set the college_start_semester after the watch for the graduation_object is triggered.
        if (cookieInfo['college_start_semester']) {
          this.$nextTick().then(() => {
            this.form.college_start_semester = cookieInfo['college_start_semester'];
          });
        }

        this.rememberd_high_school_id = this.form.high_school_id;
        this.previousSelectedSchool = cookieInfo['school'];
      }
    },
    storeFormData() {
      const data = { ...this.form };
      delete data['accept_tos'];
      delete data['fair'];
      data.school = this.selectedSchool;
      this.$cookies.set(this.cookie_name, JSON.stringify(data), '1d');
    },
    validate_phone(value) {
      let regex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
      let error_message = this.$translate('The phone must be 10 characters.');
      if (value && !regex.test(value)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { phone: [error_message] });
        return false;
      }
      this.remove_form_field_errors('phone', error_message);
      return true;
    },
    validate_sat_score: validateSATScoreMethod,
    validate_act_score: validateACTScoreMethod,
    validate_email: validateEmailMethod,
    validate_confirm_email() {
      let error_message = this.$translate('The email confirmation does not match.');
      if (this.form.confirm_email !== this.form.email) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { confirm_email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('confirm_email', error_message);
      return true;
    },
    validate_birthdate: validateBirthdateMethod,
    validate_grad_type: validateGradTypeMethod,
    uncheck_ethnicity_radio_buttons(value) {
      this.hispanic_latino_spanish_origin = value;
      if (value == this.previously_selected_hispanic_latino_spanish_origin) {
        this.hispanic_latino_spanish_origin = null;
      }
      this.previously_selected_hispanic_latino_spanish_origin = this.hispanic_latino_spanish_origin;
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=USA&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFairValidation: clearFairValidationMethod,
    update_show_address() {
      this.show_address = true;
    },
    setStudentForm(student) {
      // Show fields that usually only show after google auto completion.
      this.autocompleted = true;

      // populate personal information
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.form.phone = student.phone;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);

      this.form.address_line_1 = student.address.line_1;
      this.form.address_line_2 = student.address.line_2;
      this.form.address_city = student.address.city;
      this.form.address_region = student.address.region;
      this.form.address_postal_code = student.address.postal_code;

      // populate student information
      this.form.high_school = student.high_school;
      this.form.high_school_city = student.high_school_city;
      this.form.high_school_region = student.high_school_region;
      this.form.high_school_id = student.school_id;
      this.form.high_school_ceeb = student.CEEB;

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      // populate Academics
      if (student.gpa > 0) {
        this.form.gpa = parseFloat(student.gpa).toFixed(2);
      }
      if (student.gpa_max > 0) {
        this.form.gpa_max = parseFloat(student.gpa_max).toFixed(2);
      }
      if (student.sat_score > 0) {
        this.form.sat_score = student.sat_score;
      }
      if (student.act_score > 0) {
        this.form.act_score = student.act_score;
      }

      this.form.accept_tos = true;

      // text permission
      this.form.text_permission = student.text_permission;

      // populate ethnicities
      if (student.has_hispanic_latino_or_spanish_origin) {
        this.hispanic_latino_spanish_origin = 'true';
        this.previously_selected_hispanic_latino_spanish_origin = 'true';
        for (let i = 0; i < student.ethnicities.length; i++) {
          this.ethnicities.push(student.ethnicities[i].ethnicity);
        }
      } else if (student.has_hispanic_latino_or_spanish_origin == 0) {
        this.previously_selected_hispanic_latino_spanish_origin = 'false';
        this.hispanic_latino_spanish_origin = 'false';
      }

      // populate races
      for (let k = 0; k < student.races.length; k++) {
        this.races.push(student.races[k].race);
      }

      // pre-populate Academic interests
      let interests_array = [];
      if (student.area_of_interest_1) {
        interests_array.push({ value: this.$translate(student.area_of_interest_1) });
      }

      if (student.area_of_interest_2) {
        interests_array.push({ value: this.$translate(student.area_of_interest_2) });
      }

      if (student.area_of_interest_3) {
        interests_array.push({ value: this.$translate(student.area_of_interest_3) });
      }
      this.selectedInterests = interests_array;

      if (interests_array.length >= 3) {
        let input = $('#interests-search input');
        input.hide();
      }

      this.resetInterestPositions();
      this.resetInterests();

      if (student.phone && student.phone.indexOf('+1') > -1) {
        this.form.phone = student.phone.replace('+1', '');
      }

      // Set Grade and expected college start date.
      for (let i = 0; i < this.gradeTypes.length; i++) {
        if (student.graduation_year == this.gradeTypes[i].year) {
          this.graduation_object = this.gradeTypes[i];
        } else if (this.gradeTypes[i].year === 0 && student.grad_type === this.gradeTypes[i].type) {
          this.graduation_object = this.gradeTypes[i];
        }
      }
      this.form.college_start_semester = student.college_start_semester;

      // text me my barcode
      this.form.text_confirmation = student.notify_by_text;

      // What event are you attending?
      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;
    },
    fillInAddress() {
      var place = this.google_autocomplete.getPlace();

      let fields = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'long_name',
        postal_code_suffix: 'long_name'
      };

      for (let i = 0; i < place.address_components.length; i++) {
        let address_type = place.address_components[i].types[0];

        fields[address_type] = place.address_components[i][fields[address_type]];
      }

      let street_number = fields.street_number !== 'short_name' ? fields.street_number : '';
      let route_name = fields.route !== 'long_name' ? fields.route : '';
      let street_number_and_route_name = '';
      street_number_and_route_name =
        street_number && route_name ? street_number + ' ' + route_name : street_number ? street_number : route_name;
      document.getElementById('autocomplete').value = street_number_and_route_name;
      this.form.address_line_1 = street_number_and_route_name;

      /*
                Brooklyn and other parts of New York City do not include the city as part of the address.
                They use sublocality_level_1 instead.
                @see https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
             */
      let locality = fields.locality !== 'long_name' ? fields.locality : '';
      if (!locality) {
        locality = fields.sublocality_level_1 !== 'long_name' ? fields.sublocality_level_1 : '';
      }
      document.getElementById('locality').value = locality;
      this.form.address_city = locality;

      let administrative_area_level_1 = fields.administrative_area_level_1 !== 'short_name' ? fields.administrative_area_level_1 : '';
      document.getElementById('administrative_area_level_1').value = administrative_area_level_1;
      this.form.address_region = administrative_area_level_1;

      // Not every address has a postal code suffix
      let postal_code = fields.postal_code !== 'long_name' ? fields.postal_code : '';
      let postal_code_suffix = fields.postal_code_suffix !== 'long_name' ? fields.postal_code_suffix : '';
      let post_code_and_suffix =
        postal_code && postal_code_suffix ? postal_code + '-' + postal_code_suffix : postal_code ? postal_code : postal_code_suffix;
      document.getElementById('address_postal_code').value = post_code_and_suffix;
      this.form.address_postal_code = post_code_and_suffix;
      this.autocompleted = true;
    },
    positionError(error) {
      console.error(error);

      this.loading = false;
      this.ok_to_query_state = true;
    },
    getFairsInRegion: getFairsInRegionMethod,
    getRegionFromGoogleAPIResponse(address_components) {
      for (let i = 0; i < address_components.length; i++) {
        let types = address_components[i].types;
        for (let k = 0; k < types.length; k++) {
          if (types[k] === 'administrative_area_level_1') {
            let inner_component = address_components[i].address_components;
            for (let q = 0; q < inner_component.length; q++) {
              if (this.isValidStateShort(inner_component[q].short_name)) {
                return inner_component[q].short_name;
              }
            }
          }
        }
      }
      return '';
    },
    isValidStateShort(state_short) {
      let is_valid = false;
      for (let i = 0; i < this.states.length; i++) {
        if (this.states[i].abbreviation === state_short || this.states[i].name === state_short) {
          is_valid = true;
        }
      }
      return is_valid;
    },
    setUpcomingFairs: setUpcomingFairsMethod,
    signup() {
      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      if (this.remember_confirmation) {
        this.storeFormData();
      }
      const data = Object.assign({}, this.form, { phone: this.form.phone.replace(/[^0-9]+/g, '') });

      this.isSubmitDisabled = true;

      let url = '/students';
      let method = 'post';

      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      } else if (typeof currentLocale !== 'undefined' && currentLocale !== 'en') {
        url += `/${currentLocale}`;
      }

      const recaptchaInput = document.querySelector('input[name="g-recaptcha-response"]');
      if (recaptchaInput) {
        data['g-recaptcha-response'] = recaptchaInput.value;
      }

      Spark[method](url, data)
        .then((response) => {
          const url = new URL(response.redirect);
          let searchParams = url.searchParams;

          if (this.redirectTo) {
            searchParams.set('redirectTo', this.redirectTo);
          }

          url.search = searchParams.toString();

          const newUrl = url.toString();
          window.location = newUrl;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      if (this.form.fair) {
        query['fair_id'] = this.form.fair;
      }

      this.schoolQuery = search;

      queryURL = '/api/v2/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
          if (this.rememberd_high_school_id) {
            const school = this.schoolItems.find((item) => item.id === this.rememberd_high_school_id);

            if (school) {
              this.selectedSchool = school;
              this.rememberd_high_school_id = null;
              this.showHighschoolFields = school.CEEB === CEEB_UNLISTED || school.CEEB === CEEB_HOMESCHOOLED;
            } else if (this.previousSelectedSchool) {
              this.selectedSchool = this.previousSelectedSchool;
              this.schoolItems.push(this.previousSelectedSchool);
              this.rememberd_high_school_id = null;
              this.showHighschoolFields =
                this.previousSelectedSchool.CEEB === CEEB_UNLISTED || this.previousSelectedSchool.CEEB === CEEB_HOMESCHOOLED;
            }
          }
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.rememberd_high_school_id = null;

        this.isHomeSchooled = false;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school_id = school.id;

        if (school.CEEB === CEEB_UNLISTED) {
          this.form.high_school = this.schoolQuery || '';
          this.form.high_school_city = '';
          this.form.high_school_region = '';

          if (student) {
            this.form.high_school = student.high_school;
            this.form.high_school_city = student.high_school_city;
            this.form.high_school_region = student.high_school_region;
          }

          this.showHighschoolFields = true;
        } else if (school.CEEB === CEEB_HOMESCHOOLED) {
          this.form.high_school = school.school;
          this.form.high_school_city = this.form.address_city;
          this.form.high_school_region = this.form.address_region;
          this.showHighschoolFields = false;
          this.isHomeSchooled = true;
        } else {
          this.form.high_school = school.school;
          this.form.high_school_city = school.city;
          this.form.high_school_region = school.state;
          this.showHighschoolFields = false;
        }
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.isHomeSchooled = false;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';
      this.rememberd_high_school_id = null;

      this.schoolQuery = '';
      this.querySchoolApi('');
    },
    resetInterests() {
      let interests = [];

      this.interests.forEach((i, index) => {
        i.children.forEach((ii) => {
          interests.push({
            value: ii
          });
        });
      });

      // Computed differences so they won't show up again when selected
      interests = differenceBy(interests, this.selectedInterests, 'value');

      this.interestItems = interests;

      if (this.$refs.interestRef) {
        this.$refs.interestRef.inputValue = '';
      }
    },
    resetInterestPositions() {
      this.form.area_of_interest_1 = this.selectedInterests[0] ? this.selectedInterests[0].value : '';
      this.form.area_of_interest_2 = this.selectedInterests[1] ? this.selectedInterests[1].value : '';
      this.form.area_of_interest_3 = this.selectedInterests[2] ? this.selectedInterests[2].value : '';
    },
    queryInterests(query) {
      let interests = [];

      this.resetInterests();

      this.interestItems.filter((str) => {
        if (str.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          interests.push({
            value: str.value
          });
        }
      });

      this.interestItems = interests;
    },
    selectInterest(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedInterests.length < this.maxInterests) {
        this.selectedInterests.push(selectedObj);

        this.$nextTick(() => {
          let input = $('#interests-search input');
          input.val('');
          input.blur();

          if (this.selectedInterests.length >= 3) {
            input.hide();
          }
        });

        this.resetInterestPositions();
      }

      this.resetInterests();
    },
    removeInterest(index) {
      this.selectedInterests.splice(index, 1);
      this.resetInterestPositions();

      this.resetInterests();

      let input = $('#interests-search input');

      if (this.selectedInterests.length < 3) {
        input.show();
      }
    },
    getGraduationYear(addYears = 0) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        offsetYear = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + addYears + 1;
      } else {
        offsetYear = currentYear + addYears;
      }

      return offsetYear;
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropdown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    }
  }
});
