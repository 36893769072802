import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup-cis', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    return {
      countries: [],
      fields_to_validate: {
        fair: ['required'],
        first_name: ['required'],
        last_name: ['required'],
        email: ['required', 'custom'],
        email_confirmation: ['required', 'custom'],
        country_code: ['required'],
        phone: ['required'],
        phone_country_code: ['required'],
        birthdate: ['required'],
        birthdate_month: ['required'],
        birthdate_day: ['required'],
        birthdate_year: ['required'],
        high_school: ['custom'],
        high_school_city: ['custom'],
        grade_level: ['required'],
        college_start_semester: ['required'],
        high_school_country_code: ['required'],
        graduation_year: ['required']
      },
      form: new SparkForm({
        first_name: '',
        last_name: '',
        fair: '',
        email: '',
        country_code: '',
        phone: '',
        phone_country_code: 'US',
        text_permission: false,
        birthdate: '',
        country_of_citizenship_1: '',
        country_of_citizenship_2: '',
        gender: '',
        gender_specify: '',
        grade_level: '',
        high_school: '',
        high_school_city: '',
        high_school_region: '',
        college_start_semester: '',
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        country_of_interest_1: '',
        country_of_interest_2: '',
        country_of_interest_3: '',
        email_confirmation: '',
        text_confirmation: true,
        authorize_cis: false,
        accept_tos: false,
        high_school_ceeb: '',
        high_school_id: '',
        high_school_country_code: '',
        birthdate_day: '',
        birthdate_month: '',
        birthdate_year: '',
        current_year_class: '',
        graduation_year: null,
        has_parent_permission: false
      }),
      google_autocomplete: null,
      citizenships: [],
      maxCitizenships: 2,
      grades: [6, 7, 8, 9, 10, 11, 12],
      start_university: [],
      selectedInterests: [],
      interestItems: [],
      interestQuery: '',
      interests: [
        {
          main: 'Undecided',
          children: ['Undecided']
        },
        {
          main: 'Agriculture, Agriculture Operations, and Related Sciences',
          children: ['Agricultural Business and Management', 'Animal Sciences']
        },
        {
          main: 'Architecture and Related Services',
          children: ['Architecture', 'City/Urban, Community, and Regional Planning', 'Landscape Architecture']
        },
        {
          main: 'Area, Ethnic, Cultural, and Gender Studies',
          children: ['Area Studies', 'Ethnic, Cultural Minority, and Gender Studies']
        },
        {
          main: 'Aviation and Aeronautics',
          children: ['Aviation and Aeronautics']
        },
        {
          main: 'Biological and Biomedical Sciences',
          children: [
            'Biology/Biological Sciences, General',
            'Biochemistry',
            'Biophysics',
            'Biotechnology',
            'Cell/Cellular Biology and Anatomical Sciences',
            'Ecology',
            'Genetics',
            'Marine Biology and Biological Oceanography',
            'Microbiological Sciences and Immunology',
            'Molecular Biology',
            'Zoology/Animal Biology'
          ]
        },
        {
          main: 'Business Management, Marketing, and Related Support Services',
          children: [
            'Accounting and Related Services',
            'Actuarial Science',
            'Business Administration, Management and Operations',
            'Finance and Financial Management Services',
            'Hospitality Administration/Management',
            'Human Resources Management and Services',
            'International Business',
            'Management Information Systems and Services',
            'Marketing/Marketing Management, General'
          ]
        },
        {
          main: 'Commerce',
          children: ['Commerce']
        },
        {
          main: 'Communication, Journalism, and Related Programs',
          children: [
            'Advertising',
            'Communication and Media Studies',
            'Digital Communication and Media/Multimedia',
            'Journalism',
            'Public Relations/Image Management',
            'Radio and Television'
          ]
        },
        {
          main: 'Computer and Information Sciences and Support Services',
          children: ['Computer Science', 'Information Science/Studies']
        },
        {
          main: 'Education',
          children: [
            'Early Childhood Education and Teaching',
            'Elementary Education and Teaching',
            'Secondary Education and Teaching',
            'Special Education and Teaching'
          ]
        },
        {
          main: 'Engineering',
          children: [
            'Aerospace, Aeronautical and Astronautical Engineering',
            'Agricultural/Biological Engineering and Bioengineering',
            'Architectural Engineering',
            'Biomedical/Medical Engineering',
            'Chemical Engineering',
            'Civil Engineering',
            'Computer Engineering, General',
            'Electrical, Electronics and Communications Engineering',
            'Engineering Physics',
            'Engineering Science',
            'Environmental/Environmental Health Engineering',
            'Geological/Geophysical Engineering',
            'Industrial Engineering',
            'Materials Engineering',
            'Mechanical Engineering',
            'Mining and Mineral Engineering',
            'Nuclear Engineering',
            'Petroleum Engineering',
            'Materials Science',
            'Polymer/Plastics Engineering'
          ]
        },
        {
          main: 'Engineering Technologies/Technicians',
          children: [
            'Computer Engineering Technology/Technician',
            'Drafting/Design Engineering Technologies/Technician',
            'Telecommunications Technology/Technician'
          ]
        },
        {
          main: 'English Language and Literature/Letters',
          children: ['Creative Writing']
        },
        {
          main: 'Family and Consumer Sciences/Human Sciences',
          children: ['Foods, Nutrition and Wellness Studies, General']
        },
        {
          main: 'Foreign Languages, Literatures, and Linguistics',
          children: [
            'Classics and Classical Languages, Literatures, and Linguistics',
            'Comparative Literature',
            'East Asian Languages, Literatures, and Linguistics',
            'French Language and Literature',
            'German Language and Literature',
            'Linguistics',
            'Russian Language and Literature',
            'Spanish Language and Literature'
          ]
        },
        {
          main: 'Health Professions and Related Clinical Services',
          children: [
            'Allied Health Diagnostic, Intervention, and Treatment Professions',
            'Athletic Training/Trainer',
            'Clinical/Medical Laboratory Science and Allied Professions',
            'Communication Disorders Sciences and Services',
            'Dental Hygiene/Hygienist',
            'Dietetics and Clinical Nutrition Services',
            'Nursing',
            'Occupational Therapy/Therapist',
            'Physical Therapy/Therapist',
            'Pre-Dentistry Studies',
            'Pre-Medicine/Pre-Medical Studies',
            'Pre-Pharmacy Studies',
            'Pre-Veterinary Studies'
          ]
        },
        {
          main: 'History',
          children: ['History']
        },
        {
          main: 'Law',
          children: ['Law']
        },
        {
          main: 'Legal Professions and Studies',
          children: ['Pre-Law Studies']
        },
        {
          main: 'Liberal Arts and Sciences, General Studies, and Humanities',
          children: ['Humanities/Humanistic Studies', 'Liberal Arts and Sciences/Liberal Studies']
        },
        {
          main: 'Mathematics and Statistics',
          children: ['Applied Mathematics', 'Mathematics', 'Statistics']
        },
        {
          main: 'Medicine',
          children: ['Medicine']
        },
        {
          main: 'Multi/Interdisciplinary Studies',
          children: ['Biological and Physical Sciences', 'International/Global Studies', 'Mathematics and Computer Science', 'Neuroscience']
        },
        {
          main: 'Natural Resources and Conservation',
          children: [
            'Environmental Science',
            'Environmental Studies',
            'Fishing and Fisheries Sciences and Management',
            'Forestry',
            'Wildlife and Wildlands Science and Management'
          ]
        },
        {
          main: 'Parks, Recreation, Leisure, and Fitness Studies',
          children: ['Parks, Recreation, and Leisure Facilities Management', 'Sport and Fitness Administration/Management']
        },
        {
          main: 'Personal and Culinary Services',
          children: ['Personal and Culinary Services']
        },
        {
          main: 'Philosophy and Religious Studies',
          children: ['Philosophy', 'Religion/Religious Studies']
        },
        {
          main: 'Physical Sciences',
          children: [
            'Astronomy',
            'Astrophysics',
            'Atmospheric Sciences and Meteorology',
            'Chemistry',
            'Geological and Earth Sciences/Geosciences',
            'Physics'
          ]
        },
        {
          main: 'Psychology',
          children: ['Psychology']
        },
        {
          main: 'Public Administration and Social Service Professions',
          children: ['Human Services, General', 'Public Administration', 'Public Policy Analysis', 'Social Work']
        },
        {
          main: 'Security and Protective Services',
          children: ['Criminal Justice/Law Enforcement Administration', 'Forensic Science and Technology']
        },
        {
          main: 'Social Sciences',
          children: [
            'Anthropology',
            'Archaeology',
            'Economics',
            'Geography',
            'International Relations and Affairs',
            'Political Science and Government',
            'Sociology'
          ]
        },
        {
          main: 'Theology and Religious Vocations',
          children: ['Theology and Religious Vocations']
        },
        {
          main: 'Visual and Performing Arts',
          children: [
            'Art History, Criticism, and Conservation',
            'Dance',
            'Drama and Dramatics, Theatre Arts, General',
            'Fashion/Apparel Design',
            'Film/Video and Photographic Arts',
            'Fine and Studio Art',
            'Graphic Design',
            'Interior Design',
            'Music',
            'Technical Theatre/Theatre Design and Technology'
          ]
        },
        {
          main: 'Other',
          children: ['Other']
        }
      ],
      maxInterests: 3,
      selectedCountries: [],
      maxCountries: 3,
      countryItems: [],
      countryQuery: '',
      isSubmitDisabled: false,
      birthdate: '',
      phone: '',
      upcomingFairs: [],
      isHomeSchooled: false,
      schoolItems: [],
      schoolQuery: '',
      showHighschoolFields: false,
      availableCountries: [],
      student: student,
      months: [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
      ],
      gradeSelectors: [],
      grade_data: null,
      requiresParent: false,
      requiresParentUnderAge: 16,
      twilioFailure: '',
      filter_query: null,
      citizenshipQuery: '',
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropDown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      loading: false
    };
  },
  computed: {
    isNonBinaryGender() {
      return !(this.form.gender === 'male' || this.form.gender === 'female') && this.form.gender !== '';
    }
  },
  watch: {
    grade_data: function() {
      this.form.graduation_year = this.grade_data.label;
      if (!this.grade_data || (this.grade_data && this.grade_data.grade === null)) return;

      let selectedIndex = this.start_university.indexOf(`August - December ${this.grade_data.classOf}`);

      if (selectedIndex > -1) {
        this.form.college_start_semester = this.start_university[selectedIndex];
      }
    },
    'form.graduation_year': function() {
      let app = this;
      let gradeItem = this.gradeSelectors.filter(function(item) {
        return item.label === app.form.graduation_year;
      });

      let selectedIndex = this.start_university.indexOf(`August - December ${gradeItem.firstObject().classOf}`);

      if (selectedIndex > -1) {
        this.form.college_start_semester = this.start_university[selectedIndex];
      }
    },
    'form.accept_tos': function() {
      let error_message = 'You must agree before continuing.';
      if (!this.form.accept_tos) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { accept_tos: [error_message] });
      } else {
        this.remove_form_field_errors('accept_tos', error_message);
      }
    },
    'form.has_parent_permission': function() {
      let error_message = 'You must agree that you have permission before continuing.';
      if (this.requiresParent && !this.form.has_parent_permission) {
        this.isSubmitDisabled = true;
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { has_parent_permission: [error_message] });
      } else {
        this.isSubmitDisabled = false;
        this.remove_form_field_errors('has_parent_permission', error_message);
      }
    },
    'form.birthdate_day': function() {
      this.birthdate = this.formatBirthdate();
      this.validateAge();
    },
    'form.birthdate_month': function() {
      this.birthdate = this.formatBirthdate();
      this.validateAge();
    },
    'form.birthdate_year': function() {
      this.birthdate = this.formatBirthdate();
      this.validateAge();
    },
    upcomingFairs: function() {
      if (this.upcomingFairs.length === 1) {
        this.form.fair = this.upcomingFairs[0].id;
      }
    },
    'form.gender': function() {
      if (this.form.gender.toLowerCase() === 'male' || this.form.gender.toLowerCase() === 'female') {
        this.form.gender_specify = '';
      }
    },
    'form.fair': function() {
      $(() => {
        let selectedFair;

        this.querySchoolApi('');

        if (!this.student) {
          selectedFair = this.upcomingFairs.filter((f) => {
            return f.id === this.form.fair;
          });
        } else {
          let fairData = {
            id: this.student.fair.id,
            name: this.student.fair.name,
            country: this.student.fair.venue.address.country_code
          };
          selectedFair = [fairData];
        }

        if (!selectedFair) return;

        let selectedCountry = this.countries.filter((c) => {
          return c.alpha2 === selectedFair[0].country;
        });

        if (selectedCountry.length) {
          iti.setCountry(selectedCountry[0].alpha2);
        }
      });
    }
  },

  created() {
    this.countries = countries;
    this.studyCountries = countries;
    this.availableCountries = countries;
  },
  mounted() {
    let app = this;

    if (typeof query != 'undefined') {
      this.filter_query = query;
    }

    $('#gender_specify').on('click', function() {
      app.resetGender();
    });

    $('#gender_specify_input').on('click', function() {
      $('#gender_specify').click();
    });

    $(() => {
      $('#intl-phone').on('countrychange', (e, countryData) => {
        if (countryData && countryData.iso2) {
          this.form.phone_country_code = countryData.iso2.toUpperCase();
        }
        this.validate('phone_country_code', this.form.phone_country_code);
      });
    });

    for (let i = 0; i <= 6; i++) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear();
      let offsetYear = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + 1 + i;
      } else {
        offsetYear = currentYear + i;
      }

      // Create Grade Item
      let classOf = offsetYear + (Math.abs(12 - this.grades[i]) - Math.abs(6 - this.grades[i]));

      let gradeItem = {
        grade: this.grades[i],
        classOf: classOf,
        label: `${classOf}`
      };

      this.gradeSelectors.push(gradeItem);
    }

    this.gradeSelectors.sort((a, b) => {
      return a.classOf - b.classOf;
    });

    // These are isolated pieces which should focus per the current year
    let currentDate = new Date(),
      currentYear = currentDate.getFullYear(),
      startOfTrimesterOne = new Date(`01/01/${currentYear}`),
      startOfTrimesterTwo = new Date(`05/01/${currentYear}`),
      startOfTrimesterThree = new Date(`08/01/${currentYear}`);

    if (currentDate <= startOfTrimesterOne) {
      this.start_university.push(`January - April ${currentYear}`);
    }

    if (currentDate <= startOfTrimesterTwo) {
      this.start_university.push(`May - July ${currentYear}`);
    }

    if (currentDate <= startOfTrimesterThree) {
      this.start_university.push(`August - December ${currentYear}`);
    }

    for (let i = 1; i <= 9; i++) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear();

      let offsetYearStartUniv = currentYear + i;
      let jaStrRepresentation = `January - April ${offsetYearStartUniv}`;
      let mjStrRepresentation = `May - July ${offsetYearStartUniv}`;
      let adStrRepresentation = `August - December ${offsetYearStartUniv}`;
      this.start_university.push(jaStrRepresentation);
      this.start_university.push(mjStrRepresentation);
      this.start_university.push(adStrRepresentation);
    }

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;
    }

    this.isSubmitDisabled = false;

    this.resetInterests();
    this.resetCountries();
    this.getLocation();
  },

  methods: {
    handleFairRadiusOnChanged(event) {
      this.fairRadius = event.target.value;
      if (this.lat && this.lng) {
        let lat, lng;
        lat = this.lat;
        lng = this.lng;
        this.setUpcomingFairs({ lat, lng });
      }
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropDown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    },
    setUpcomingFairs(query) {
      this.loading = true;
      query.student_type = 'CIS';

      if (query.lat && query.lng) {
        query.radius = this.fairRadius;
      }

      query.occurrence = 'upcoming';
      query.limit = '999';

      let url = '/api/v2/fairs/students/index';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        url += `?${queryString}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          this.upcomingFairs = data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }

          if (this.fairRadius === 'unknown' && data.meta && data.meta.radius) {
            let radius = data.meta.radius;
            if (radius <= 50) {
              this.fairRadius = 50;
            } else if (radius > 50 && radius <= 100) {
              this.fairRadius = 100;
            } else if (radius > 100 && radius <= 200) {
              this.fairRadius = 200;
            } else {
              this.fairRadius = 'any';
            }
          }
        })
        .catch((error) => {
          this.upcomingFairs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = 'The email must be a valid email address.';
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email', error_message);
      return true;
    },
    validate_email_confirmation(value) {
      let error_message = 'The email confirmation does not match.';
      if (value !== this.form.email) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email_confirmation: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email_confirmation', error_message);
      return true;
    },
    validate_college_start_semester(value) {
      let error_message = 'The university start semester field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          college_start_semester: ['The university start semester field is required.']
        });
        return false;
      }
      this.remove_form_field_errors('college_start_semester', error_message);
      return true;
    },
    validate_high_school(value) {
      let error_message = 'The school field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { high_school: ['The school field is required.'] });
        return false;
      }
      this.remove_form_field_errors('high_school', error_message);
      return true;
    },
    validate_high_school_city(value) {
      let error_message = 'The school city field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { high_school_city: ['The school city field is required.'] });
        return false;
      }
      this.remove_form_field_errors('high_school_city', error_message);
      return true;
    },
    validate_current_year_class(value) {
      let error_message = 'The graduation year field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          current_year_class: ['The graduation year field is required.']
        });
        return false;
      }
      this.remove_form_field_errors('current_year_class', error_message);
      return true;
    },
    resetCountriesList() {
      let countries = window.countries;

      countries = differenceBy(countries, this.citizenships, 'name');

      this.countries = countries;

      if (this.$refs.citizenshipRef) {
        this.$refs.citizenshipRef.inputValue = '';
      }
    },
    resetCountryOfCitizenshipPositions() {
      this.form.country_of_citizenship_1 = this.citizenships[0] ? this.citizenships[0].alpha2 : '';
      this.form.country_of_citizenship_2 = this.citizenships[1] ? this.citizenships[1].alpha2 : '';
    },
    queryCountry(query) {
      let countryArr = [];

      this.countries.filter((country) => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
          countryArr.push(country);
        } else {
          this.resetCountriesList();
        }
      });

      if (query === null) this.resetCountriesList();

      this.countries = countryArr;
    },
    selectCountryOfCitizenship(selected, index) {
      if (selected === null && index === -1) {
        this.resetCountriesList();
      }

      if (selected && this.citizenships.length < this.maxCitizenships) {
        this.citizenships.push(selected);
        let input = $('#citizenship-country-search input');
        input.val(null);
        input.blur();

        if (this.citizenships.length >= this.maxCitizenships) input.hide();

        this.resetCountriesList();
      }

      this.remove_form_field_errors('country_of_citizenship_1', 'The country of citizenship 1 field is required.');

      this.resetCountryOfCitizenshipPositions();
    },
    removeCitizenship(index) {
      this.citizenships.splice(index, 1);
      this.resetCountriesList();

      let input = $('#citizenship-country-search input');

      if (this.citizenships.length < this.maxCitizenships) {
        input.show();
      }

      this.resetCountryOfCitizenshipPositions();
    },
    resetInterests() {
      let interests = [];

      this.interests.forEach((i, index) => {
        i.children.forEach((ii) => {
          interests.push({
            value: ii
          });
        });
      });

      // Computed differences so they won't show up again when selected
      interests = differenceBy(interests, this.selectedInterests, 'value');

      this.interestItems = interests;

      if (this.$refs.interestRef) {
        this.$refs.interestRef.inputValue = '';
      }
    },
    resetInterestPositions() {
      this.form.area_of_interest_1 = this.selectedInterests[0] ? this.selectedInterests[0].value : '';
      this.form.area_of_interest_2 = this.selectedInterests[1] ? this.selectedInterests[1].value : '';
      this.form.area_of_interest_3 = this.selectedInterests[2] ? this.selectedInterests[2].value : '';
    },
    queryInterests(query) {
      let interests = [];

      this.resetInterests();

      this.interestItems.filter((str) => {
        if (str.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          interests.push({
            value: str.value
          });
        }
      });

      this.interestItems = interests;
    },
    selectInterest(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedInterests.length < this.maxInterests) {
        this.selectedInterests.push(selectedObj);
        let input = $('#interests-search input');
        input.val('');
        input.blur();

        if (this.selectedInterests.length >= 3) {
          input.hide();
        }

        this.resetInterestPositions();
      }

      this.resetInterests();
    },
    removeInterest(index) {
      this.selectedInterests.splice(index, 1);
      this.resetInterestPositions();

      this.resetInterests();

      let input = $('#interests-search input');

      if (this.selectedInterests.length < 3) {
        input.show();
      }
    },
    removeCountries(index) {
      this.selectedCountries.splice(index, 1);
      this.resetCountryPosition();

      let input = $('#countries-search input');

      if (this.selectedCountries.length < 3) {
        input.show();
      }
    },
    resetCountries() {
      let countries = this.studyCountries;

      countries = differenceBy(countries, this.selectedCountries, 'name');

      this.countryItems = countries;

      if (this.$refs.countriesRef) {
        this.$refs.countriesRef.inputValue = '';
      }
    },
    resetCountryPosition() {
      this.form.country_of_interest_1 = this.selectedCountries[0] ? this.selectedCountries[0].alpha2 : '';
      this.form.country_of_interest_2 = this.selectedCountries[1] ? this.selectedCountries[1].alpha2 : '';
      this.form.country_of_interest_3 = this.selectedCountries[2] ? this.selectedCountries[2].alpha2 : '';
    },
    queryCountries(query) {
      let countries = [];

      this.resetCountries();

      this.countryItems.filter((item) => {
        if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          countries.push({
            name: item.name,
            alpha2: item.alpha2
          });
        }
      });

      this.countryItems = countries;
    },
    selectCountry(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedCountries.length < this.maxCountries) {
        this.selectedCountries.push(selectedObj);
        let input = $('#countries-search input');
        input.val('');
        input.blur();

        if (this.selectedCountries.length >= this.maxCountries) {
          input.hide();
        }

        this.resetCountries();
        this.resetCountryPosition();
      }
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      if (this.form.fair) {
        query['fair_id'] = this.form.fair;
      }

      this.schoolQuery = search;

      queryURL = '/api/v2/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.isHomeSchooled = false;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school_id = school.id;

        if (school.CEEB === CEEB_UNLISTED) {
          this.form.high_school = this.schoolQuery || '';
          this.form.high_school_city = '';
          this.form.high_school_region = '';

          if (student) {
            this.form.high_school = student.high_school;
            this.form.high_school_city = student.high_school_city;
            this.form.high_school_region = student.high_school_region;
          }

          this.showHighschoolFields = true;
        } else if (school.CEEB === CEEB_HOMESCHOOLED) {
          this.form.high_school = school.school;
          this.form.high_school_city = this.form.address_city;
          this.form.high_school_region = this.form.address_region;
          this.showHighschoolFields = false;
          this.isHomeSchooled = true;
        } else {
          this.form.high_school = school.school;
          this.form.high_school_city = school.city;
          this.form.high_school_region = school.state;
          this.showHighschoolFields = false;
          this.validate('high_school', this.form.high_school);
        }
      }
    },
    signup() {
      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      if (this.phone) {
        let intlPhoneInput = $('#intl-phone');
        let number = intlPhoneInput.val(),
          countryData = iti.getSelectedCountryData();
        this.form.phone_country_code = countryData.iso2.toUpperCase();
        this.form.phone = number;
      }

      if (this.form.gender !== 'female' && this.form.gender !== 'male') {
        this.form.gender = this.form.gender_specify;
      }

      this.isSubmitDisabled = true;

      let url = '/cis';
      let method = 'post';
      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      }

      const recaptchaInput = document.querySelector('input[name="g-recaptcha-response"]');
      if (recaptchaInput) {
        this.form['g-recaptcha-response'] = recaptchaInput.value;
      }
      
      Spark[method](url, this.form)
        .then((response) => {
          window.location = response.redirect;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (this.form.gender !== 'female' && this.form.gender !== 'male') {
            this.form.gender = 'specify';
          }

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    setStudentForm(student) {
      // Popular required personal fields
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.form.email_confirmation = student.email;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);
      this.form.text_permission = student.text_permission;
      this.form.has_parent_permission = student.has_parent_permission;

      let citizenship_country_1 = this.countries.filter((country) => {
        return country.alpha2 === student.country_of_citizenship_1;
      })[0];
      let citizenship_country_2 = this.countries.filter((country) => {
        return country.alpha2 === student.country_of_citizenship_2;
      })[0];

      if (student.country_of_citizenship_1 && student.country_of_citizenship_1 !== '') {
        this.form.country_of_citizenship_1 = student.country_of_citizenship_1;
        this.citizenships.push(citizenship_country_1);
      }

      if (student.country_of_citizenship_2 && student.country_of_citizenship_2 !== '') {
        this.form.country_of_citizenship_2 = student.country_of_citizenship_2;
        this.citizenships.push(citizenship_country_2);
      }

      if (student.gender.toLowerCase() !== 'female' && student.gender.toLowerCase() !== 'male') {
        this.form.gender = 'specify';
        this.form.gender_specify = student.gender;
      } else {
        this.form.gender = student.gender;
      }

      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;

      this.form.high_school = student.high_school;
      this.form.high_school_ceeb = student.CEEB;
      this.form.high_school_city = student.high_school_city;
      this.form.high_school_region = student.high_school_region;
      this.form.high_school_country_code = student.high_school_country;
      this.form.high_school_id = student.school_id;
      this.form.grade_level = student.grade_level;

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      this.form.college_start_semester = student.college_start_semester;

      // pre-populate Academic interests
      let interests_array = [];
      if (student.area_of_interest_1) {
        interests_array.push({ value: student.area_of_interest_1 });
      }

      if (student.area_of_interest_2) {
        interests_array.push({ value: student.area_of_interest_2 });
      }

      if (student.area_of_interest_3) {
        interests_array.push({ value: student.area_of_interest_3 });
      }
      this.selectedInterests = interests_array;

      if (interests_array.length >= 3) {
        let input = $('#interests-search input');
        input.hide();
      }

      // Set Birthdate
      let splitBirthdate = this.birthdate.split('/');
      this.form.birthdate_month = splitBirthdate[0];
      this.form.birthdate_day = splitBirthdate[1];
      this.form.birthdate_year = splitBirthdate[2];

      // Set Current Year / Grade Level
      if (student.graduation_year && student.graduation_year !== '') {
        let currentYearGradeLevel = this.gradeSelectors.filter((item) => {
          return item.classOf === student.graduation_year;
        });

        this.grade_data = currentYearGradeLevel[0];
      }

      this.resetInterestPositions();
      this.resetInterests();

      // Country of Interests
      let country_of_interests_1 = this.countries.filter((country) => {
        return country.alpha2 === student.country_of_interest_1;
      })[0];
      let country_of_interests_2 = this.countries.filter((country) => {
        return country.alpha2 === student.country_of_interest_2;
      })[0];
      let country_of_interests_3 = this.countries.filter((country) => {
        return country.alpha2 === student.country_of_interest_3;
      })[0];

      this.form.country_of_interest_1 = student.country_of_interest_1;
      this.form.country_of_interest_2 = student.country_of_interest_2;
      this.form.country_of_interest_3 = student.country_of_interest_3;

      if (country_of_interests_1) this.selectedCountries.push(country_of_interests_1);
      if (country_of_interests_2) this.selectedCountries.push(country_of_interests_2);
      if (country_of_interests_3) this.selectedCountries.push(country_of_interests_3);

      let countryOfInterestInput = $('#countries-search input');
      if (this.selectedCountries.length >= this.maxCountries) {
        countryOfInterestInput.hide();
      }

      let countryOfCitizenshipInput = $('#citizenship-country-search input');

      this.resetCountryOfCitizenshipPositions();
      this.resetCountryPosition();

      this.form.text_confirmation = student.notify_by_text;

      this.form.authorize_cis = student.authorize_cis;

      this.validateAge();
      if (this.requiresParent) {
        this.form.has_parent_permission = true;
      }
      this.form.accept_tos = true;

      // Set Phone number
      this.form.phone = student.phone_number.number;
      this.form.phone_country_code = student.phone_number.country_code;

      if (student.phone_number) {
        this.phone = student.phone_number.formatted_number;

        $(() => {
          iti.setCountry(student.phone_number.country_code);
          iti.setNumber(student.phone_number.number);
        });
      }
    },
    formatBirthdate() {
      return `${this.form.birthdate_month}/${this.form.birthdate_day}/${this.form.birthdate_year}`;
    },
    validateAge() {
      let isValidBirthdate = moment(this.birthdate, ['MM/D/YYYY', 'MM/DD/YYYY'], true).isValid();
      if (this.form.birthdate_day && this.form.birthdate_month && this.form.birthdate_year && isValidBirthdate) {
        const age = moment().diff(new Date(this.birthdate), 'years');
        this.requiresParent = false;
        if (age < this.requiresParentUnderAge) {
          this.requiresParent = true;
          this.isSubmitDisabled = !this.form.has_parent_permission;
        } else {
          this.isSubmitDisabled = false;
        }
      }
    },
    resetGender() {
      if (this.form.gender.toLowerCase() === 'male' || this.form.gender.toLowerCase() === 'female') {
        this.form.gender = 'specify';
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.isHomeSchooled = false;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';

      this.schoolQuery = '';
      this.querySchoolApi('');
    },
    clearFairValidation() {
      this.$nextTick(() => {
        this.remove_form_field_errors('fair', 'The fair field is required.');
      });
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=CIS&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
