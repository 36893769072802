<template>
  <div>
    <div id="modal-event-guide-upload-list" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" aria-hidden="true" @click="resetAndClose">
              &times;
            </button>
            <h3 class="modal-title">{{ modalTitle }}</h3>
          </div>
          <div class="modal-body">
            <div class="panel-body">
              <div style="margin-bottom: 10px;">
                <p>{{ modalMessage }}</p>
              </div>
              <div v-if="type === null">
                <!-- give the user 2 big buttons as options, upload or copy & paste-->
                <div class="button-container">
                  <button class="btn btn-primary" @click="type = 'paste'">
                    Copy & Paste
                  </button>
                  <button class="btn btn-primary" @click="type = 'file'">
                    Upload List
                  </button>
                </div>
              </div>
              <div v-if="type === 'paste' && jsonData === null">
                <div class="form-group">
                  <label for="participants">Participants</label>
                  <textarea class="form-control" id="pasteParticipants" v-model="pasteParticipants"
                    rows="10"></textarea>
                </div>
              </div>
              <div v-if="type === 'file' && jsonData === null">
                <div class="dropzone" id="dropzone" @click="triggerFileInput" @dragover.prevent="handleDragOver"
                  @dragleave="handleDragLeave" @drop.prevent="handleFileDrop">
                  <div class="dz-message text-center">
                    <i class="fa fa-upload"></i>
                    <p>Drag & drop your file here.<br />Files must be in .csv or Excel (.xls or .xlsx) format.</p>
                  </div>
                </div>
                <input type="file" ref="fileInput" @change="handleFileSelect" accept=".csv,.xls,.xlsx"
                  style="display: none;" />
              </div>
              <div v-if="jsonData !== null && results === null">
                <div class="form-group">
                  <label for="institution_name">Institution Name</label>
                  <select class="form-control" id="institution_name" v-model="selectedInstitutionNameIndex">
                    <option v-for="(column, index) in jsonData[0]" :key="index" :value="index" :disabled="index === selectedEmailIndex || index === selectedLocationIndex">
                      {{ column }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="representative_email">Representative Email</label>
                  <select class="form-control" id="representative_email" v-model="selectedEmailIndex">
                    <option v-for="(column, index) in jsonData[0]" :key="index" :value="index" :disabled="index === selectedInstitutionNameIndex || index === selectedLocationIndex">
                      {{ column }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="location" style="width: 100%;">Location at Event <span style="color: #585E71; float: right; font-weight: 400;">optional</span></label>
                  <select class="form-control" id="location" v-model="selectedLocationIndex">
                    <option v-for="(column, index) in jsonData[0]" :key="index" :value="index" :disabled="index === selectedInstitutionNameIndex || index === selectedEmailIndex">
                      {{ column }}
                    </option>
                  </select>
                </div>
                <div class="form-check">
                  <input class="form-check" type="checkbox" id="include_header" v-model="includeHeader"
                    :disabled="jsonData.length === 1">
                  <label class="form-check" for="include_header">
                    Include header row
                  </label>
                </div>

                <div class="table-responsive" style="padding-top: 10px;">
                  <p>Preview</p>
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in ['Institution Name', 'Email', 'Location']" :key="index">
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in jsonData.slice(includeHeader ? 0 : 1, 3)" :key="index">
                        <td>
                          {{ row[selectedInstitutionNameIndex] }}
                        </td>
                        <td>
                          {{ row[selectedEmailIndex] }}
                        </td>
                        <td>
                          {{ row[selectedLocationIndex] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <small>
                      Found {{ jsonData.length - (!includeHeader ? 1 : 0) }} participants to import.
                    </small>
                  </p>
                </div>
              </div>
              <div v-if="results !== null">
                <ul style="list-style-type: none; padding: 0;">
                  <li style="margin-bottom: 10px;">Imported successfully: {{ results.new_participants.length }}</li>
                  <li style="margin-bottom: 10px;">Updated participants: {{ results.updated_participants.length }}</li>
                  <li style="margin-bottom: 10px;">Duplicates skipped: {{ results.existing_participants.length }}</li>
                  <li style="margin-bottom: 10px;">Errors not imported: {{ results.bad_participants.length }}</li>
                </ul>
                <div v-if="results.updated_participants.length > 0" style="background-color: #E8F4FF; color: #0066CC; padding: 8px; border-radius: 4px; margin-bottom: 10px;">
                  <p>
                    <strong>The following participants were updated with new information:</strong>
                  </p>
                  <ul style="max-height: 100px; overflow: scroll;">
                    <li v-for="(email, key) in results.updated_participants" :key="key">
                      {{ email }}
                    </li>
                  </ul>
                  <div class="scroll-indicator" v-if="results.updated_participants.length > 5">
                    <i class="fa fa-arrow-down scroll-arrow"></i>
                  </div>
                </div>
                <div v-if="results.bad_participants.length > 0" style="background-color: #FFE8E4; color: #AA3522; padding: 8px; border-radius: 4px;">
                  <p>
                    <strong>The following rows were not imported due to the listed errors:</strong>
                  </p>
                  <ul style="max-height: 100px; overflow: scroll;">
                    <li v-for="(participant, key) in results.bad_participants" :key="key">
                      Row {{ key + 1 }}, {{ participant.error.name ? participant.error.name + ', ' + participant.name : '' }} {{ participant.error.email ? participant.error.email + ', ' + participant.email : '' }}
                    </li>
                  </ul>
                  <div class="scroll-indicator" v-if="results.bad_participants.length > 5">
                    <i class="fa fa-arrow-down scroll-arrow"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <template v-if="!form.busy">
              <button class="btn btn-secondary" @click="resetData" v-if="form.participants.length > 0 && results === null">
                BACK
              </button>
              <button class="btn btn-secondary" style="background: none;" @click="resetAndClose" v-if="results === null">
                CANCEL
              </button>
              <button class="btn btn-primary" style="margin-left: 10px;" v-if="form.participants.length > 0 && results === null" @click="save" :disabled="selectedEmailIndex === null || selectedInstitutionNameIndex === null">
                SAVE
              </button>
              <button class="btn btn-secondary" style="background: none;" v-if="results !== null" @click="successfulClose">
                CLOSE
              </button>
            </template>
            <template v-else>
              <div class="">
                Processing
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                  <i class="fa fa-spinner fa-spin primary-state"></i>
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';

export default {
  name: 'EventGuideUploadListModal',

  props: {
    fair: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'file'
    }
  },

  data() {
    return {
      form: new SparkForm({
        participants: []
      }),
      loading: true,
      jsonData: null,
      selectedEmailIndex: null,
      selectedInstitutionNameIndex: null,
      selectedLocationIndex: null,
      includeHeader: false,
      emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      pasteParticipants: '',
      results: null,
    };
  },

  computed: {
    modalTitle() {
      if (this.results !== null) {
        return 'Success!';
      } else if (this.type === 'paste') {
        return 'Copy & Paste';
      } else if (this.type === 'file') {
        return 'Upload List';
      }

      return 'Bulk Import';
    },
    modalMessage() {
      if (this.results !== null) {
        return 'The list of participants has been successfully imported.';
      } else if (this.type === 'paste') {
        return 'Copy & paste the list of participating institutions, representative email addresses, and their assigned location at the event (optional).';
      } else if (this.type === 'file') {
        return 'Upload a file containing a list of the participating institutions, representative email addresses, and their assigned location at the event (optional).';
      } else if (this.jsonData === null) {
        return 'Choose how you want to import a list of participants to the event guide.';
      }

      return 'Set the columns for the participant data.';
    }
  },

  mounted() {
    $('#modal-event-guide-upload-list').on('hidden.bs.modal', this.resetForm);
    $('#modal-event-guide-upload-list').on('shown.bs.modal', this.setFormData);
  },

  methods: {
    closeModal() {
      $('#modal-event-guide-upload-list').modal('hide');
    },
    resetData() {
      this.form = new SparkForm({
        participants: []
      });
      this.jsonData = null;
      this.selectedEmailIndex = null;
      this.selectedInstitutionNameIndex = null;
      this.selectedLocationIndex = null;
      this.includeHeader = false;
      this.pasteParticipants = '';
      this.results = null;
    },
    resetAndClose() {
      this.resetData();
      this.closeModal();
    },
    successfulClose() {
      this.resetAndClose();
    },
    save() {
      this.form.startProcessing();

      axios.post(`/api/dashboards/fairs/${this.fair.id}/fair-participants`, this.form).then(
        (success) => {
          this.form.finishProcessing();
          this.results = success.data.data;

          Bus.$emit('uploadedEventGuideParticipants');
        }).catch(
          (error) => {
            this.form.setErrors(error.response.data);
          }
        );
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.processFile(file);
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
      event.currentTarget.classList.add('drag-over');
    },
    handleDragLeave(event) {
      event.currentTarget.classList.remove('drag-over');
    },
    handleFileDrop(event) {
      const file = event.dataTransfer.files[0];
      this.processFile(file);
      event.currentTarget.classList.remove('drag-over');
    },
    processFile(file) {
      // Process the file (e.g., upload it, read its content, etc.)
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];
        this.jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        this.preselectColumns();
        this.setIncludeHeader();
        this.setInstitutions();
      };
      reader.readAsArrayBuffer(file);
    },
    findEmailColumn() {
      for (let i = 0; i < this.jsonData.length; i++) {
        const dataRow = this.jsonData[i];
        const emailColumnIndex = dataRow.findIndex(column => this.emailRegex.test(column));

        if (emailColumnIndex !== -1) {
          this.selectedEmailIndex = emailColumnIndex;
          break;
        }
      }
    },
    preselectColumns() {
      this.findEmailColumn();

      const dataRow = this.jsonData[1] ?? this.jsonData[0] ?? null;

      for (let i = 0; i < dataRow.length; i++) {
        if (i !== this.selectedEmailIndex) {
          if (this.jsonData.length > 1) {
            if (/institution|school|name/i.test(this.jsonData[0][i])) {
              this.selectedInstitutionNameIndex = i;
              break;
            }
          } else {
            this.selectedInstitutionNameIndex = i;
            break;
          }
        }
      }

      for (let i = 0; i < dataRow.length; i++) {
        if (i !== this.selectedEmailIndex && i !== this.selectedInstitutionNameIndex) {
          if (this.jsonData.length > 1) {
            if (this.jsonData[0][i].toLowerCase().indexOf('location') > -1) {
              this.selectedLocationIndex = i;
              break;
            }
          } else {
            this.selectedLocationIndex = i;
            break;
          }
        }
      }

      // Fallback to the first non-selected row for institution
      if (this.selectedInstitutionNameIndex === null) {
        for (let i = 0; i < dataRow.length; i++) {
          if (i !== this.selectedEmailIndex) {
            this.selectedInstitutionNameIndex = i;
            break;
          }
        }
      }
    },
    setIncludeHeader() {
      this.includeHeader = this.jsonData.length === 1 || this.jsonData[0].some(column => this.emailRegex.test(column));
    },
    setInstitutions() {
      if (this.jsonData === null) {
        return;
      }
        
      const startIndex = this.includeHeader ? 0 : 1;
      
      // Function to decode special characters
      const decodeSpecialChars = (text) => {
        if (!text) return '';
        
        // First remove surrounding quotes if present
        let decoded = text.toString().replace(/^"(.*)"$/, '$1');
        
        // Replace common problematic encodings
        return decoded
          .replace(/â\u0080\u0099/g, "'")  // Apostrophe
          .replace(/â\u0080\u0093/g, "–")  // En dash
          .replace(/â\u0080\u0094/g, "—")  // Em dash
          .replace(/â\u0080\u00A9/g, "©")  // Copyright
          .replace(/â\u0080\u00AE/g, "®")  // Registered trademark
          .replace(/â\u0080\u2122/g, "™")  // Trademark
          .replace(/â\u0080\u201C/g, "\"")  // Left double quotation
          .replace(/â\u0080\u201D/g, "\"")  // Right double quotation
          .replace(/â\u0080\u2018/g, "'")  // Left single quotation
          .replace(/â\u0080\u2019/g, "'"); // Right single quotation
      };

      this.form.participants = this.jsonData.slice(startIndex).map(row => {
        const name = decodeSpecialChars(row[this.selectedInstitutionNameIndex]);
        const location = row[this.selectedLocationIndex] ? decodeSpecialChars(row[this.selectedLocationIndex]) : '';

        return {
          'name': name,
          'email': row[this.selectedEmailIndex],
          'location': location,
        };
      });
    }
  },

  watch: {
    selectedEmailIndex() {
      this.setInstitutions();
    },
    selectedInstitutionNameIndex() {
      this.setInstitutions();
    },
    selectedLocationIndex() {
      this.setInstitutions();
    },
    includeHeader() {
      this.setInstitutions();
    },
    pasteParticipants() {
      if (this.pasteParticipants === '') {
        this.jsonData = null;
        return;
      }

      this.jsonData = this.pasteParticipants
        .split('\n')
        .filter(row => row.trim() !== '') // Remove empty rows
        .map(row => {
          const columns = row.split('\t');
          return columns;
        });

      this.preselectColumns();
      this.setIncludeHeader();
      this.setInstitutions();
    }
  }
};
</script>

<style scoped>
.full-width-btn {
  width: 100%;
  border-radius: 54px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.button-container .btn {
  flex: 1;
  height: 100px;
}

.dropzone {
  background-color: rgba(75, 85, 99, 0.05);
  border-radius: 10px;
  padding: 60px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.dropzone i {
  font-size: 24px;
}

.dropzone:hover {
  background-color: #ccc;
}

.drag-over {
  background-color: #bbb;
}

.scroll-arrow {
  position: absolute;
  right: 35px;
  bottom: 35px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>